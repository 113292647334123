import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { notify } from 'actions/app'
import { clientUpdateDownload, fetchClientDownloadUpdate, fetchClientJobDownloads } from 'actions/client'
import { fetchJobDownloadStatus, updateJobDownload } from 'actions/job'
import { downloadJobDownload, putStartDownload } from 'utils/api/job'
import { downloadStatuses } from 'utils/business/jobs'

const useDownloads = (jobId) => {
  const dispatch = useDispatch()

  const isExternal = useSelector(state => (
    state.app.user
      ? state.app.user.isExternal
      : true
  ))

  const { downloads, isFetching, isFetched } = useSelector((state) => {
    if (isExternal) {
      const dls = state.client.jobs[jobId] && state.client.jobs[jobId].downloads
        ? state.client.jobs[jobId].downloads
        : []
      return {
        downloads: dls,
        isFetching: state.client.jobs[jobId] && state.client.jobs[jobId]._isFetchedDownloads,
        isFetched: state.client.jobs[jobId] && state.client.jobs[jobId]._isFetchingDownloads,
      }
    }

    const dls = state.job && state.job.downloads
      ? state.job.downloads
      : []
    return {
      downloads: dls,
      isFetching: state.job._isFetchingDownloads,
      isFetched: state.job._isFetchedDownloads,
    }
  })

  useEffect(() => {
    if (jobId && !isFetching) {
      if (isExternal) {
        dispatch(fetchClientJobDownloads(jobId))
      }
      // TODO: implement internal to use hook
    }
  }, [dispatch, jobId, isExternal, isFetching])

  const getDownloadUpdate = (downloadName) => {
    if (isExternal) {
      dispatch(fetchClientDownloadUpdate(jobId, downloadName))
    } else {
      dispatch(fetchJobDownloadStatus(jobId, downloadName))
    }
  }

  const handleJobDownload = downloadName => (
    downloadJobDownload(jobId, downloadName, isExternal)
  )

  const updateJobDownloadStatus = (downloadName, status) => {
    if (isExternal) {
      dispatch(clientUpdateDownload(jobId, { name: downloadName, status }))
    } else {
      dispatch(updateJobDownload(jobId, downloadName, { status }))
    }
  }

  const requestJobDownload = (downloadName) => {
    updateJobDownloadStatus(downloadName, downloadStatuses.requesting)
    putStartDownload(jobId, downloadName, isExternal)
      .then(() => {
        updateJobDownloadStatus(downloadName, downloadStatuses.downloading)
      })
      .catch(() => {
        dispatch(notify('Error when requesting the download - refresh and try again', 'error'))
        updateJobDownloadStatus(downloadName, downloadStatuses.notStarted)
      })
  }

  return {
    downloads,
    isFetching,
    isFetched,
    getDownloadUpdate,
    requestJobDownload,
    handleJobDownload,
  }
}

export default useDownloads
