import moment from 'moment'

export const makeDate = dateString => (dateString ? moment(dateString, moment.ISO_8601) : null)

export const formatDateShort = date => (date ? date.format('D MMM YYYY') : null)
export const formatDateTimeShort = date => (date ? date.format('D MMM YYYY, h:mma') : null)
export const formatDateTimeLong = date => (date ? date.format('dddd D MMM, h:mma') : null)

export const formatDateFromStringShort = dateString => formatDateShort(makeDate(dateString))
export const formatDateTimeFromStringShort = dateString => formatDateTimeShort(makeDate(dateString))
export const formatDateTimeFromStringLong = dateString => formatDateTimeLong(makeDate(dateString))

export const getDifferenceInTime = (originalDate, newDate) => {
  return newDate.getTime() - originalDate.getTime()
}

export const getDifferenceInDays = (originalDate, newDate) => {
  const differenceInDays = getDifferenceInTime(originalDate, newDate) / (1000 * 3600 * 24)
  return Math.floor(differenceInDays)
}

export const getDateFromNYearsInFuture = (numberOfYears) => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + numberOfYears))
}

export const isValidDate = (d) => {
  return d instanceof Date && !Number.isNaN(d.getTime())
}
