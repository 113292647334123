import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './MultiProgressBar.scss'

function MultiProgressBar({
  denominator, value, secondaryValue, size, color, roundEdges,
}) {
  const valueFraction = (denominator !== 0) ? (value / denominator * 100.0) : 0
  const secondaryValueFraction = secondaryValue
    && (denominator !== 0) ? (secondaryValue / denominator * 100.0) : 0

  return (
    <div className={classNames(
      styles.base,
      styles[size],
      styles[color],
      roundEdges ? styles.roundEdges : null,
      valueFraction === 0 ? styles.noProgress : null,
    )}
    >
      <div
        className={classNames(
          styles.value,
          valueFraction === 100 ? styles.complete : null,
        )}
        style={{ width: `${valueFraction}%` }}
      />
      {secondaryValue !== null && (
        <div className={styles.secondaryValue} style={{ width: `${secondaryValueFraction}%` }} />
      )}
    </div>
  )
}

MultiProgressBar.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'disabled']),
  denominator: PropTypes.number.isRequired,
  roundEdges: PropTypes.bool,
  secondaryValue: PropTypes.number,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  value: PropTypes.number.isRequired,
}

MultiProgressBar.defaultProps = {
  color: 'secondary',
  secondaryValue: null,
  size: 'normal',
  roundEdges: true,
}

export default MultiProgressBar

