import { validateOrApplyValidation } from 'actionHub/utils/validations'
import { objectFromArray, objectFromArrayWithFunctions } from 'utils/objects'
import { initialState } from './initialState'
import * as actionTypes from './actionTypes'
import { revisedStatusCount, revisedActionStateCount, convertTaskResponseToTask } from './utils'

export default function actionHubReducer(incomingState = initialState, action) {
  // Reset the state if we've moved to a different actionSetId
  // All actionHub actions must have an actionSetId
  const state = 'actionSetId' in action && incomingState.actionSetId !== action.actionSetId
    ? {
      ...initialState,
      actionSetId: action.actionSetId,
    }
    : incomingState

  switch (action.type) {
    case actionTypes.ACTION_HUB_FETCHING_ACTION:
      return {
        ...state,
        actionSet: {
          ...state.actionSet,
          _isFetching: true,
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_TAG_DETAILS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? { ...t, ...action.details, _isDetailsFetched: true }
            : t)),
        },
      }
    case actionTypes.ACTION_HUB_FETCHING_TAGS:
      return {
        ...state,
        tags: {
          ...state.tags,
          _isFetching: true,
        },
      }
    case actionTypes.ACTION_HUB_TAG_CREATED:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: [
            ...state.tags.tagsList,
            action.tag,
          ],
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_TAGS:
      return {
        ...state,
        tags: {
          tagsList: action.tags.map((t) => {
            const existingTag = state.tags.tagsList.find(ft => ft.id === t.id)
            return {
              ...existingTag,
              ...t,
              labels: t.labels || existingTag?.labels,
              taskIds: existingTag?.taskIds,
              comments: existingTag?.comments,
            }
          }),
          _isFetching: false,
          _isFetched: true,
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_UPDATED_TASKS_TOKENS:
      return {
        ...state,
        taskLevel: {
          ...state.taskLevel,
          globalFilters: action.data,
        },
      }
    case actionTypes.ACTION_HUB_TAG_COMMENTS_UPDATED:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? { ...t, comments: action.comments }
            : t)),
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_ACTION:
      return {
        ...state,
        actionSet: {
          ...state.actionSet,
          ...action.actionSet,
          _isFetching: false,
          _isFetched: true,
        },
        actionStates: action.actionStates,
        statuses: action.statuses,
        priorities: action.priorities,
        commentStatuses: action.commentStatuses,
        taskLevel: {
          ...state.taskLevel,
          ...action.taskLevelMeta,
        },
        dataLevel: {
          ...state.dataLevel,
          ...action.dataLevelMeta,
        },
        isCurrent: action.isCurrent,
        displayName: action.displayName,
        allowTags: action.allowTags,
      }
    case actionTypes.ACTION_HUB_FETCHING_TASKS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          _isFetching: true,
        },
      }
    case actionTypes.ACTION_HUB_IMPORTING_ACTIONS:
      return {
        ...state,
        _isImportingAction: true,
        _isActionImported: false,
      }
    case actionTypes.ACTION_HUB_IMPORTED_ACTIONS:
      return {
        ...state,
        _isImportingAction: false,
        _isActionImported: true,
      }
    case actionTypes.ACTION_HUB_ERRORED_IMPORTING_ACTIONS:
      return {
        ...state,
        _isImportingAction: false,
        _isActionImported: false,
      }
    case actionTypes.ACTION_HUB_UPDATED_TASKS_ADD_TAG:
      for (let i = 0; i < action.dataIds.length; i += 1) {
        const task = state.tasks[action.dataIds[i]]
        if (task) {
          task.tagId = action.tagId
          task.taskData._hasTag = true
        }
      }
      return {
        ...state,
      }
    case actionTypes.ACTION_HUB_FETCHED_TASKS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.tasks.map(x => convertTaskResponseToTask(state, x)), '_id'),
          _isFetching: false,
          _isFetched: false,
        },
        taskLevel: {
          ...state.taskLevel,
          dataIds: [...new Set([
            ...state.taskLevel.dataIds,
            ...action.tasks.map(x => x._id),
          ])],
        },
        filterStatuses: {
          ...state.filterStatuses,
          [action.filterString]: action.filterStatus,
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_TASK_META:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            ...action.data,
            metaId: action.data.id,
          },
        },
      }
    case actionTypes.ACTION_HUB_FETCHING_TASK_DATA:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isFetching: true,
            _isFetched: false,
          },
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_TASK_DATA:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isFetching: false,
            _isFetched: true,
            _hasChildData: true,
            pagination: {
              page: action.data.page,
              hasNext: action.data.hasNext,
              totalItems: action.data.totalItems,
              nextPageQuery: action.data.nextPageQuery,
            },
            taskData: state.tasks[action.taskId].taskData || {},
            dataIds: [
              ...(state.tasks[action.taskId].dataIds || []),
              ...action.data.items.map(d => d._id),
            ],
          },
        },
        data: {
          ...state.data,
          ...objectFromArray(action.data.items.map(d => ({
            ...state.data[d._id],
            _id: d._id,
            _isFetched: false,
            data: d,
          })), '_id'),
        },

      }
    case actionTypes.ACTION_HUB_FETCHED_TASK_DATA_NO_DATA:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isFetching: false,
            _isFetched: true,
            _hasChildData: false,
          },
          taskData: {},
          dataIds: [],
        },
      }
    case actionTypes.ACTION_HUB_FETCHING_TAG_TASKS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(tag => (tag.id === action.tagId
            ? {
              ...tag,
              tagTasks: {
                ...tag.tagTasks,
                _isFetching: true,
              },
            }
            : tag)),
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_TAG_TASKS: {
      const selectedTag = state.tags.tagsList.find(tag => tag.id === action.tagId)

      const updatedSelectedTag = {
        ...selectedTag,
        tagTasks: {
          ...state.tags[action.tagId],
          _isFetching: false,
          _isFetched: true,
          _hasTasks: true,
          pagination: {
            page: action.data.page,
            hasNext: action.data.hasNext,
            totalItems: action.data.totalItems,
            nextPageQuery: action.data.nextPageQuery,
          },
        },
      }
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(tag => (tag.id === action.tagId
            ? updatedSelectedTag
            : tag)),
        },
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.data.items.map(x => convertTaskResponseToTask(state, x)), '_id'),
        },

      }
    }
    case actionTypes.ACTION_HUB_FETCHING_DATA_DETAIL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isFetching: true,
            _isFetched: false,
          },
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_DATA_DETAIL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            ...action.data,
            _isFetching: false,
            _isFetched: true,
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_TASK_LABEL_VALUE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isLabelsModified: true,
            labels: state.tasks[action.taskId].labels
              .map(l => (l.id === action.labelId
                ? { ...l, value: action.value }
                : l)),
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_TAG_LABEL_VALUE:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              _isLabelsModified: true,
              labels: t.labels.map(l => ((l.id === action.labelId)
                ? {
                  ...l,
                  value: action.value,
                }
                : l)),
            }
            : t)),
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTING_TASK_LABELS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isSubmittingLabels: true,
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTING_TAG_LABELS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              _isSubmittingLabels: true,
            }
            : t)),
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_TAG_DATA: {
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              name: action.name === null ? t.name : action.name,
              description: action.description === null ? t.description : action.description,
              narrative: action.narrative === null ? t.narrative : action.narrative,
            }
            : t)),
        },
      }
    }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_LABELS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isLabelsModified: false,
            _isSubmittingLabels: false,
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TAG_LABELS:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              _isLabelsModified: false,
              _isSubmittingLabels: false,
            }
            : t)),
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTING_TASK_LABELS_FAILED:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isSubmittingLabels: false,
          },
        },
      }
    case actionTypes.ACTION_HUB_VALIDATE_TASK_LABEL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            labels: state.tasks[action.taskId].labels
              .map(l => (l.id === action.labelId
                ? validateOrApplyValidation(l, action.error)
                : l)),
          },
        },
      }
    case actionTypes.ACTION_HUB_VALIDATE_TAG_LABEL:
      return {
        ...state,
        tags: {
          ...state.tags,
          tagsList: state.tags.tagsList.map(t => (t.id === action.tagId
            ? {
              ...t,
              labels: t.labels
                .map(l => (l.id === action.labelId
                  ? validateOrApplyValidation(l, action.error)
                  : l)),
            }
            : t)),
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_ASSIGNEE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            [action.assigneeType]: action.userId,
          })), '_id'),
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_ACTIONSTATE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            taskActionId: action.taskActionId,
          })), '_id'),
        },
        actionStates: state.actionStates.map(s => ({
          ...s,
          count: revisedActionStateCount(s, action.taskIds.map(id => state.tasks[id]), action.taskActionId),
        })),
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_STATUS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            statusId: action.statusId,
          })), '_id'),
        },
        statuses: state.statuses.map(s => ({
          ...s,
          count: revisedStatusCount(s, action.taskIds.map(id => state.tasks[id]), action.statusId),
        })),
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_PRIORITY:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            priorityId: action.priorityId,
          })), '_id'),
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_TAG:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            tagId: action.tagId,
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_DATA_LABEL_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isLabelsModified: true,
            labels: state.data[action.dataId].labels
              .map(l => (l.id === action.labelId
                ? { ...l, value: action.value }
                : l)),
          },
        },
      }
    case actionTypes.ACTION_HUB_VALIDATE_DATA_LABEL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            labels: state.data[action.dataId].labels
              .map(l => (l.id === action.labelId
                ? validateOrApplyValidation(l, action.error)
                : l)),
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_DATA_LABELS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isLabelsModified: false,
            data: {
              ...state.data[action.dataId].data,
              ...objectFromArrayWithFunctions(action.payload, x => `_${x.name}`, x => x.value),
            },
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTING_DATA_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            _isCommentSubmitting: true,
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_DATA_COMMENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.dataId]: {
            ...state.data[action.dataId],
            comments: [
              ...state.data[action.dataId].comments,
              action.comment,
            ],
            data: {
              ...state.data[action.dataId].data,
              _commentCount: state.data[action.dataId].data._commentCount + 1,
            },
            _isCommentSubmitting: false,
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTING_TASK_COMMENT:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            _isCommentSubmitting: true,
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_COMMENT:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            comments: [
              ...state.tasks[action.taskId].comments,
              action.comment,
            ],
            _isCommentSubmitting: false,
          },
        },
      }

    case actionTypes.ACTION_HUB_UPDATE_TASK_DETAILS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            taskData: {
              ...state.tasks[action.taskId].taskData,
              ...action.data,
            },
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATING_TASK_DETAILS:
      return {
        ...state,
        _isUpdatingDetails: action.isUpdatingDetails,
      }
    case actionTypes.ACTION_HUB_ADDING_TASK:
      return {
        ...state,
        _isAddingTask: action.isAddingTask,
      }
    case actionTypes.ACTION_HUB_SELECTED_TAB_UPDATE:
      return {
        ...state,
        selectedTab: action.selectedTab,
      }
    case actionTypes.ACTION_HUB_UPDATE_SELECTED_TASKS:
      return {
        ...state,
        selectedTasks: action.taskIds.map(id => ({
          ...state.tasks[id],
        })),
      }
    case actionTypes.ACTION_HUB_FETCH_SELECTED_TASKS:
      return {
        ...state,
        _isSelectedTasksUpdating: action.isFetching,
      }
    case actionTypes.ACTION_HUB_TASKLIST_SCROLL_LOCATION:
      return {
        ...state,
        taskListScrollLocation: action.scrollLocation,
      }
    case actionTypes.ACTION_HUB_TASKLIST_SEARCH_QUERY:
      return {
        ...state,
        taskListSearchQuery: action.searchQuery,
      }
    case actionTypes.ACTION_HUB_UPDATE_IS_COMMENT_REQUIRED:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            isCommentRequired: action.isCommentRequired,
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_IS_COMMENT_RECEIVED:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            isCommentReceived: action.isCommentReceived,
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_SIGN_OFF_NAME:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            signOffName: action.signOffName,
          },
        },
      }
    case actionTypes.ACTION_HUB_UPDATE_SIGN_OFF_DATE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            signOffDate: action.signOffDate,
          },
        },
      }
    case actionTypes.ACTION_HUB_SUBMITTED_TASK_COMMENT_STATUS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...objectFromArray(action.taskIds.map(id => ({
            ...state.tasks[id],
            commentStatusId: action.commentStatusId,
          })), '_id'),
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_TASK_COMMENTS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.taskId]: {
            ...state.tasks[action.taskId],
            comments: action.comments,
          },
        },
      }
    case actionTypes.RESET_LABELS: {
      const { taskId } = action.payload
      const task = state.tasks[taskId]

      if (!task) { return state }

      const updatedTask = {
        ...task,
        _isLabelsModified: false,
        labels: task.labels.map(label => ({
          ...label,
          value: '',
          valid: true,
          error: null,
        })),
      }

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [taskId]: updatedTask,
        },
      }
    }
    case actionTypes.ACTION_HUB_FETCHING_COMMENT_AUTHORS:
      return {
        ...state,
        commentAuthors: {
          ...state.commentAuthors,
          _isFetching: true,
        },
      }
    case actionTypes.ACTION_HUB_FETCHED_COMMENT_AUTHOR:
      return {
        ...state,
        commentAuthors: {
          ...state.commentAuthors,
          [action.userId]: action.authorData,
          _isFetching: false,
        },
      }
    default:
      return state
  }
}
