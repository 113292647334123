import { getRequest, postRequest, parseError } from 'utils/api'
import constants from 'constants'
import { notify, showErrorPage } from './app'

export const SUPPORT_UPDATE_SEARCH_TEXT = 'SUPPORT_UPDATE_SEARCH_TEXT'
export const SUPPORT_SHOW_SUPPORT_BOX = 'SUPPORT_SHOW_SUPPORT_BOX'
export const SUPPORT_HIDE_SUPPORT_BOX = 'SUPPORT_HIDE_SUPPORT_BOX'
export const SUPPORT_FETCHING_ARTICLES = 'SUPPORT_FETCHING_ARTICLES'
export const SUPPORT_FETCHED_ARTICLES = 'SUPPORT_FETCHED_ARTICLES'
export const SUPPORT_FETCH_ARTICLE_ERROR = 'SUPPORT_FETCH_ARTICLE_ERROR'
export const SUPPORT_CONTACT_FORM_SENDING = 'SUPPORT_CONTACT_FORM_SENDING'
export const SUPPORT_CONTACT_FORM_SENT = 'SUPPORT_CONTACT_FORM_SENT'
export const SUPPORT_CONTACT_FORM_DIALOG_DISMISSED = 'SUPPORT_CONTACT_FORM_DIALOG_DISMISSED'
export const SUPPORT_CONTACT_FORM_SEND_FAILED = 'SUPPORT_CONTACT_FORM_SEND_FAILED'

export const updateSupportSearchText = payload => ({
  type: SUPPORT_UPDATE_SEARCH_TEXT,
  payload,
})

export const showSupportBox = () => ({
  type: SUPPORT_SHOW_SUPPORT_BOX,
})

export const hideSupportBox = () => ({
  type: SUPPORT_HIDE_SUPPORT_BOX,
})

const fetchingArticles = () => ({
  type: SUPPORT_FETCHING_ARTICLES,

})

const fetchedArticles = articles => ({
  type: SUPPORT_FETCHED_ARTICLES,
  articles,
})

const fetchArticleError = () => ({
  type: SUPPORT_FETCH_ARTICLE_ERROR,
})

export const fetchArticles = (isExternal) => {
  return (dispatch) => {
    dispatch(fetchingArticles())

    getRequest(isExternal ? '/client/support/articles' : '/support/articles')
      .then(({ data }) => {
        dispatch(fetchedArticles(data))
      })
      .catch((error) => {
        dispatch(fetchArticleError())
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

const contactFormSending = () => ({
  type: SUPPORT_CONTACT_FORM_SENDING,
})

const contactFormSent = () => ({
  type: SUPPORT_CONTACT_FORM_SENT,
})

const contactFormSendFailed = () => ({
  type: SUPPORT_CONTACT_FORM_SEND_FAILED,
})

export const sendContactForm = () => {
  return (dispatch, getState) => {
    const { forms: { [constants.FORM_SUPPORT_CONTACT]: form } } = getState()
    const { message, subject, jobReference } = form
    postRequest('/contact', {
      contact: {
        subject: subject.value,
        message: `${(jobReference.value ? `Job reference no: ${jobReference.value}\n\n` : '')}${message.value}`,
      },
    })
      .then(() => {
        dispatch(contactFormSent())
      })
      .catch(() => {
        dispatch(notify('Your message could not be sent, please try again later.', 'error'))
      })
  }
}

export const supportContactFormDialogDismissed = () => ({
  type: SUPPORT_CONTACT_FORM_DIALOG_DISMISSED,
})

export const sendDemoRequestForm = (solutionDisplayName, selections, text) => {
  return (dispatch) => {
    dispatch(contactFormSending())

    const selectionText = Object.keys(selections)
      .filter(k => selections[k])
      .map(k => `* ${k}`)
      .join('\n')

    postRequest('/contact', {
      contact: {
        subject: `Demo request: ${solutionDisplayName}`,
        message: `${text}\n\nEnquiry is about:\n\n${selectionText}`,
      },
    })
      .then(() => {
        dispatch(contactFormSent())
      })
      .catch(() => {
        dispatch(contactFormSendFailed())
        dispatch(notify('Your message could not be sent, please try again later.', 'error'))
      })
  }
}
