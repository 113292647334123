import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReportHeader from 'components/ReportHeader'
import ReportSidebar from 'components/ReportSidebar'
import { getIndex, sortByFunction } from 'utils/arrays'
import useApp from 'hooks/useApp'
import usePackages from 'hooks/usePackages'
import { isTacTClient } from 'utils/permissions'
import useJob from 'hooks/useJob'
import styles from './Report.scss'
import EmbeddedReport from './EmbeddedReport'

const propTypes = {
  analysis: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  goToAction: PropTypes.func,
  goToReport: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isCustomReport: PropTypes.bool,
  job: PropTypes.object.isRequired,
  report: PropTypes.shape({
    heightPixels: PropTypes.number,
    type: PropTypes.string,
    viewUrl: PropTypes.string,
    widthPixels: PropTypes.number,
  }),
  returnLink: PropTypes.string.isRequired,
}

const defaultProps = {
  isCustomReport: false,
  analysis: null,
  report: null,
  goToAction: null,
}

function ReportView({
  isCustomReport,
  report,
  job,
  analysis,
  history,
  goToReport,
  goToAction,
  returnLink,
}) {
  const { user } = useApp()
  const { packages } = usePackages()
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const { isAssignedActionsEditor } = useJob(job.jobId)

  const isTactClient = isTacTClient(user.isExternal, job.packageId)

  const analyses = job.analyses ? job.analyses.filter(a => a.hasData)
    .sort(sortByFunction(x => x.displayOrder))
    : []

  const analysisIndex = getIndex(analyses, x => x.id === analysis.id) + 1

  const largeFormatPackages = [11, 18, 25, 26]
  const isLarge = largeFormatPackages.includes(job.packageId)
  const reportWidth = report.widthPixels || (isLarge ? 1710 : 1384)
  const reportHeight = report.heightPixels
    ? report.heightPixels + 22 + 28 // extra height for tab bar and toolbar
    : 818

  const goBack = () => {
    history.push(returnLink)
  }

  const actions = (job.actions && job.actions.currentActions) || []
  const currentAction = analysis && actions.find(a => a.actionSet.analysisName === analysis.id)
  const actionButton = (currentAction && !isTactClient) ? {
    label: 'View actions',
    onClick: () => goToAction(currentAction.actionSet.id),
  } : null

  const packageActionOptions = actions.filter(a => a.actionSet.scope === 'package')
    .map(a => ({
      value: a.actionSet.id,
      label: a.displayName,
      onSelect: () => goToAction(a.actionSet.id),
    })) || []

  const analysisOptions = analyses.map(a => ({
    value: a.id,
    label: a.name,
    onSelect: () => goToReport(a.id),
  }))

  return (
    <>
      {!isAssignedActionsEditor && (
        <div className={classnames(styles.base, { [styles.large]: isLarge })}>
          <ReportHeader
            actionButton={actionButton}
            goBack={goBack}
            isCustom={report.type === 'hosted'}
            jobId={job.jobId}
            navigationOptionGroups={packageActionOptions.length > 0
              ? [
                { name: 'Action Sets', values: packageActionOptions.map(x => x.value) },
                { name: 'Analyses', values: analysisOptions.map(x => x.value) },
              ]
              : null}
            navigationOptions={[
              ...packageActionOptions,
              ...analysisOptions,
            ]}
            navigationValue={analysis.id}
            user={user}
          />
          <div className={styles.content}>
            <div className={styles.embedArea}>
              {report && (
                <EmbeddedReport
                  height={reportHeight}
                  report={report}
                  width={reportWidth}
                />
              )}
            </div>
            {analysis && job.analyses && packages[job.packageId]?.meta.showParametersOnTableauSidebar && (
              <ReportSidebar
                analysis={analysis}
                analysisIndex={analysisIndex}
                isCustomReport={isCustomReport}
                job={job}
                onClose={() => setSidebarVisible(false)}
                onToggleVisible={() => setSidebarVisible(!sidebarVisible)}
                visible={sidebarVisible}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

ReportView.propTypes = propTypes
ReportView.defaultProps = defaultProps

export default ReportView
