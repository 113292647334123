import { push } from 'connected-react-router'
import {
  deleteSolutionRoleAccess,
  deleteSystemAccess,
  deleteUserRole,
  getActiveJobs,
  getBusinessUnits,
  getElevatedRoles,
  getElevatedUsers,
  getExistingUsersSearch,
  getSolutionAccess,
  getDataSourceAccess,
  getSolutionAssignableRoles,
  getDataSourceAssignableRoles,
  getSystemAccess,
  postSolutionRoleAccess,
  postDataSourceRoleAccess,
  deleteDataSourceRoleAccess,
  postSystemAccess,
  postUserRole,
  getUserPermissions,
  putUserNotes,
  getUserGroups,
} from 'utils/api/admin'
import {
  getBanners,
  getActiveBanners,
  postBanner,
  putBanner,
  patchToggleBanner,
  deleteBanner,
} from 'utils/api/banner'
import constants from 'constants'
import { objectToArray } from 'utils/objects'
import { notify, updateUserPermissions } from './app'
import { modalHide } from './modals'

export const ADMIN_FETCHING_USERS = 'ADMIN_FETCHING_USERS'
export const ADMIN_FETCHED_USERS = 'ADMIN_FETCHED_USERS'
export const ADMIN_FETCHING_USER_GROUPS = 'ADMIN_FETCHING_USER_GROUPS'
export const ADMIN_FETCHED_USER_GROUPS = 'ADMIN_FETCHED_USER_GROUPS'
export const ADMIN_FETCHING_ROLES = 'ADMIN_FETCHING_ROLES'
export const ADMIN_FETCHED_ROLES = 'ADMIN_FETCHED_ROLES'
export const ADMIN_SELECT_USER = 'ADMIN_SELECT_USER'
export const ADMIN_SEARCHING_USERS = 'ADMIN_SEARCHING_USERS'
export const ADMIN_SEARCHED_USERS = 'ADMIN_SEARCHED_USERS'
export const ADMIN_CLEAR_USER_SEARCH = 'ADMIN_CLEAR_USER_SEARCH'
export const ADMIN_TOGGLE_USER_PERMISSION = 'ADMIN_TOGGLE_USER_PERMISSION'
export const ADMIN_UPDATING_USER_PERMISSIONS = 'ADMIN_UPDATING_USER_PERMISSIONS'
export const ADMIN_UPDATED_USER_PERMISSIONS = 'ADMIN_UPDATED_USER_PERMISSIONS'

export const ADMIN_FETCHING_SOLUTION_ACCESS = 'ADMIN_FETCING_SOLUTION_ACCESS'
export const ADMIN_FETCHING_DATA_SOURCE_ACCESS = 'ADMIN_FETCING_DATA_SOURCE_ACCESS'
export const ADMIN_FETCHED_SOLUTION_ACCESS = 'ADMIN_FETCHED_SOLUTION_ACCESS'
export const ADMIN_FETCHED_DATA_SOURCE_ACCESS = 'ADMIN_FETCHED_DATA_SOURCE_ACCESS'
export const ADMIN_FETCHING_SOLUTION_ROLES = 'ADMIN_FETCING_SOLUTION_ROLES'
export const ADMIN_FETCHING_DATA_SOURCE_ROLES = 'ADMIN_FETCHING_DATA_SOURCE_ROLES'
export const ADMIN_FETCHED_SOLUTION_ROLES = 'ADMIN_FETCHED_SOLUTION_ROLES'
export const ADMIN_FETCHED_DATA_SOURCE_ROLES = 'ADMIN_FETCHED_DATA_SOURCE_ROLES'
export const ADMIN_EDIT_SOLUTION_ACCESS = 'ADMIN_EDIT_SOLUTION_ACCESS'
export const ADMIN_EDIT_DATA_SOURCE_ACCESS = 'ADMIN_EDIT_DATA_SOURCE_ACCESS'
export const ADMIN_TOGGLE_SOLUTION_PERMISSION = 'ADMIN_TOGGLE_SOLUTION_PERMISSION'
export const ADMIN_TOGGLE_DATA_SOURCE_PERMISSION = 'ADMIN_TOGGLE_DATA_SOURCE_PERMISSION'
export const ADMIN_TOGGLE_ANALYSIS_PERMISSION = 'ADMIN_TOGGLE_ANALYSIS_PERMISSION'
export const ADMIN_UPDATING_SOLUTION_PERMISSIONS = 'ADMIN_UPDATING_SOLUTION_PERMISSIONS'
export const ADMIN_UPDATING_DATA_SOURCE_PERMISSIONS = 'ADMIN_UPDATING_DATA_SOURCE_PERMISSIONS'
export const ADMIN_UPDATED_SOLUTION_PERMISSIONS = 'ADMIN_UPDATED_SOLUTION_PERMISSIONS'
export const ADMIN_UPDATED_DATA_SOURCE_PERMISSIONS = 'ADMIN_UPDATED_DATA_SOURCE_PERMISSIONS'

export const ADMIN_FETCHING_SYSTEM_ACCESS = 'ADMIN_FETCHING_SYSTEM_ACCESS'
export const ADMIN_FETCHED_SYSTEM_ACCESS = 'ADMIN_FETCHED_SYSTEM_ACCESS'
export const ADMIN_FETCHING_BUSINESS_UNITS = 'ADMIN_FETCHING_BUSINESS_UNITS'
export const ADMIN_FETCHED_BUSINESS_UNITS = 'ADMIN_FETCHED_BUSINESS_UNITS'
export const ADMIN_UPDATING_SYSTEM_ACCESS = 'ADMIN_UPDATING_SYSTEM_ACCESS'
export const ADMIN_UPDATED_SYSTEM_ACCESS = 'ADMIN_UPDATED_SYSTEM_ACCESS'

export const ADMIN_FETCHING_ACTIVE_JOBS = 'ADMIN_FETCHING_ACTIVE_JOBS'
export const ADMIN_FETCHED_ACTIVE_JOBS = 'ADMIN_FETCHED_ACTIVE_JOBS'

export const ADMIN_SELECT_BANNER = 'ADMIN_SELECT_BANNER'
export const ADMIN_RESET_DEFAULT_BANNER = 'ADMIN_RESET_DEFAULT_BANNER'
export const ADMIN_UPDATE_ACTIVE_BANNERS = 'ADMIN_UPDATE_ACTIVE_BANNERS'
export const ADMIN_FETCHING_BANNERS = 'ADMIN_FETCHING_BANNERS'
export const ADMIN_FETCHED_BANNERS = 'ADMIN_FETCHED_BANNERS'
export const ADMIN_FETCHED_ACTIVE_BANNERS = 'ADMIN_FETCHED_ACTIVE_BANNERS'
export const ADMIN_UPDATING_BANNER = 'ADMIN_UPDATING_BANNER'
export const ADMIN_UPDATED_BANNER = 'ADMIN_UPDATED_BANNER'
export const ADMIN_TOGGLED_BANNER = 'ADMIN_TOGGLED_BANNER'
export const ADMIN_DELETED_BANNER = 'ADMIN_DELETED_BANNER'
export const ADMIN_RESET_BANNER_CTA = 'ADMIN_RESET_BANNER_CTA'

const adminFetchingActiveJobs = () => ({
  type: ADMIN_FETCHING_ACTIVE_JOBS,
})

const adminFetchedActiveJobs = jobs => ({
  type: ADMIN_FETCHED_ACTIVE_JOBS,
  jobs,
})

export const adminFetchActiveJobs = () => {
  return (dispatch) => {
    dispatch(adminFetchingActiveJobs())

    getActiveJobs()
      .then(({ data }) => {
        dispatch(adminFetchedActiveJobs(data))
      })
  }
}

const adminFetchingUsers = () => ({
  type: ADMIN_FETCHING_USERS,
})

const adminFetchedUsers = users => ({
  type: ADMIN_FETCHED_USERS,
  users,
})

export const adminFetchUsers = () => {
  return (dispatch) => {
    dispatch(adminFetchingUsers())
    getElevatedUsers()
      .then(({ data }) => {
        dispatch(adminFetchedUsers(data))
      })
      .catch(() => {
        dispatch(notify('Error when fetching elevated users', 'error'))
        dispatch(adminFetchedUsers([]))
      })
  }
}

const adminFetchingUserGroups = () => ({
  type: ADMIN_FETCHING_USER_GROUPS,
})

const adminFetchedUserGroups = userGroups => ({
  type: ADMIN_FETCHED_USER_GROUPS,
  userGroups,
})

export const adminFetchUserGroups = () => {
  return (dispatch) => {
    dispatch(adminFetchingUserGroups())
    getUserGroups()
      .then(({ data }) => dispatch(adminFetchedUserGroups(data)))
      .catch(() => {
        dispatch(notify('Error fetching user groups', 'error'))
        dispatch(adminFetchedUserGroups({}))
      })
  }
}

const adminFetchingRoles = () => ({
  type: ADMIN_FETCHING_ROLES,
})

const adminFetchedRoles = roles => ({
  type: ADMIN_FETCHED_ROLES,
  roles,
})

export const adminFetchRoles = () => {
  return (dispatch) => {
    dispatch(adminFetchingRoles())
    getElevatedRoles()
      .then(({ data }) => {
        dispatch(adminFetchedRoles(data))
      })
      .catch(() => {
        dispatch(notify('Error when fetching elevated roles', 'error'))
        dispatch(adminFetchedRoles([]))
      })
  }
}

export const adminSelectUser = user => ({
  type: ADMIN_SELECT_USER,
  user,
})

const adminSearchingUsers = () => ({
  type: ADMIN_SEARCHING_USERS,
})

const adminSearchedUsers = results => ({
  type: ADMIN_SEARCHED_USERS,
  results,
})

export const adminSearchUsers = (query) => {
  return (dispatch) => {
    dispatch(adminSearchingUsers())
    getExistingUsersSearch(query)
      .then(({ data }) => dispatch(adminSearchedUsers(data)))
      .catch(() => {
        dispatch(notify('Error when searching users', 'error'))
        dispatch(adminSearchedUsers([]))
      })
  }
}

export const adminClearUserSearch = () => ({
  type: ADMIN_CLEAR_USER_SEARCH,
})

export const adminToggleUserPermission = roleId => ({
  type: ADMIN_TOGGLE_USER_PERMISSION,
  roleId,
})

const adminUpdatingUserPermissions = () => ({
  type: ADMIN_UPDATING_USER_PERMISSIONS,
})

export const adminUpdatedUserPermissions = (user, selectedRoles, email, userNotes) => ({
  type: ADMIN_UPDATED_USER_PERMISSIONS,
  user,
  selectedRoles,
  email,
  userNotes,
})

export const adminUpdateUserPermissions = (user, selectedRoles, userNotes) => {
  return (dispatch, getState) => {
    dispatch(adminUpdatingUserPermissions())
    const userEmail = getState().app.user.email

    const modifiedRoles = objectToArray(selectedRoles).filter(r => r.modifiedValue !== r.originalValue)
    const removedRoles = modifiedRoles.filter(r => !r.modifiedValue)
    const addedRoles = modifiedRoles.filter(r => r.modifiedValue)

    Promise.all([
      putUserNotes(user.details.email, userNotes),
      ...removedRoles.map(rr => deleteUserRole(user.details.username, rr.id)),
      ...addedRoles.map(ar => postUserRole(user.details.email, ar.id)),
    ])
      .then(() => {
        dispatch(adminUpdatedUserPermissions(user, selectedRoles, userEmail, userNotes))
        dispatch(modalHide(constants.MODAL_USER_PERMISSIONS))
        getUserPermissions()
          .then(({ data }) => {
            dispatch(updateUserPermissions(data))
            dispatch(notify('Permissions updated successfully'))
          })
          .catch(() => {
            dispatch(notify('Error when updating user permission - refresh and check user', 'error'))
          })
      })
      .catch(() => {
        dispatch(adminUpdatedUserPermissions(null))
        dispatch(modalHide(constants.MODAL_USER_PERMISSIONS))
        dispatch(notify('Error when updating user permission - refresh and check user', 'error'))
      })
  }
}

const adminFetchingSolutionAccess = () => ({
  type: ADMIN_FETCHING_SOLUTION_ACCESS,
})

const adminFetchingDataSourceAccess = () => ({
  type: ADMIN_FETCHING_DATA_SOURCE_ACCESS,
})

const adminFetchedSolutionAccess = solutions => ({
  type: ADMIN_FETCHED_SOLUTION_ACCESS,
  solutions,
})

const adminFetchedDataSourceAccess = dataSources => ({
  type: ADMIN_FETCHED_DATA_SOURCE_ACCESS,
  dataSources,
})

export const adminFetchSolutionsAccess = () => {
  return (dispatch) => {
    dispatch(adminFetchingSolutionAccess())
    getSolutionAccess()
      .then(({ data }) => dispatch(adminFetchedSolutionAccess(data)))
      .catch(() => {
        dispatch(notify('Error when getting solution access information', 'error'))
        dispatch(adminFetchedSolutionAccess([]))
      })
  }
}

export const adminFetchDataSourcesAccess = () => {
  return (dispatch) => {
    dispatch(adminFetchingDataSourceAccess())
    getDataSourceAccess()
      .then(({ data }) => dispatch(adminFetchedDataSourceAccess(data)))
      .catch(() => {
        dispatch(notify('Error when getting data source access information', 'error'))
        dispatch(adminFetchedDataSourceAccess([]))
      })
  }
}

const adminFetchingSolutionRoles = () => ({
  type: ADMIN_FETCHING_SOLUTION_ROLES,
})

const adminFetchingDataSourceRoles = () => ({
  type: ADMIN_FETCHING_DATA_SOURCE_ROLES,
})

const adminFetchedSolutionRoles = roles => ({
  type: ADMIN_FETCHED_SOLUTION_ROLES,
  roles,
})

const adminFetchedDataSourceRoles = roles => ({
  type: ADMIN_FETCHED_DATA_SOURCE_ROLES,
  roles,
})

export const adminFetchSolutionRoles = () => {
  return (dispatch) => {
    dispatch(adminFetchingSolutionRoles())
    getSolutionAssignableRoles()
      .then(({ data }) => dispatch(adminFetchedSolutionRoles(data)))
      .catch(() => {
        dispatch(push('/home'))
        dispatch(notify('Error when getting solution roles', 'error'))
      })
  }
}

export const adminFetchDataSourceRoles = () => {
  return (dispatch) => {
    dispatch(adminFetchingDataSourceRoles())
    getDataSourceAssignableRoles()
      .then(({ data }) => dispatch(adminFetchedDataSourceRoles(data)))
      .catch(() => {
        dispatch(push('/home'))
        dispatch(notify('Error when getting data source roles', 'error'))
      })
  }
}

export const adminEditSolutionAccess = solutionName => ({
  type: ADMIN_EDIT_SOLUTION_ACCESS,
  solutionName,
})

export const adminEditDataSourceAccess = dataSourceName => ({
  type: ADMIN_EDIT_DATA_SOURCE_ACCESS,
  dataSourceName,
})

export const adminToggleSolutionPermissions = (roleId, on) => ({
  type: ADMIN_TOGGLE_SOLUTION_PERMISSION,
  roleId,
  on,
})

export const adminToggleDataSourcePermissions = (roleId, on) => ({
  type: ADMIN_TOGGLE_DATA_SOURCE_PERMISSION,
  roleId,
  on,
})

export const adminToggleAnalysisPermissions = (analysisName, roleId, on) => ({
  type: ADMIN_TOGGLE_ANALYSIS_PERMISSION,
  roleId,
  analysisName,
  on,
})

const adminUpdatingSolutionPermissions = () => ({
  type: ADMIN_UPDATING_SOLUTION_PERMISSIONS,
})

const adminUpdatingDataSourcePermissions = () => ({
  type: ADMIN_UPDATING_DATA_SOURCE_PERMISSIONS,
})

const adminUpdatedSolutionPermissions = success => ({
  type: ADMIN_UPDATED_SOLUTION_PERMISSIONS,
  success,
})

const adminUpdatedDataSourcePermissions = success => ({
  type: ADMIN_UPDATED_DATA_SOURCE_PERMISSIONS,
  success,
})

export const adminUpdateSolutionPermissions = () => {
  return (dispatch, getState) => {
    dispatch(adminUpdatingSolutionPermissions())

    const { solutionAccess, assignableRoles: { roleIds } } = getState().admin

    const roleAnalysesToAdd = roleIds
      .filter(rid => solutionAccess.roles.map(r => r.id).includes(rid))
      .map(rid => ({
        roleId: rid,
        analyses: solutionAccess.analyses.filter(a => a.roles.map(r => r.id).indexOf(rid) >= 0).map(a => a.name),
      })).filter(add => add.analyses.length >= 0)

    const roleAnalysesToRemove = roleIds
      .filter(rid => !solutionAccess.roles.map(r => r.id).includes(rid))
      .map(rid => ({
        roleId: rid,
        analyses: solutionAccess.analyses.filter(a => a.roles.map(r => r.id).indexOf(rid) < 0).map(a => a.name),
      })).filter(remove => remove.analyses.length >= 0)

    const afterUpdate = (message) => {
      dispatch(notify(message))
      dispatch(adminUpdatedSolutionPermissions())
      dispatch(modalHide(constants.MODAL_SOLUTION_SETTINGS))
    }

    Promise.all(
      [
        ...roleAnalysesToAdd.map(add => postSolutionRoleAccess(solutionAccess.name, add.roleId, add.analyses)),
        ...roleAnalysesToRemove.map(remove => deleteSolutionRoleAccess(solutionAccess.name, remove.roleId, remove.analyses)),
      ],
    )
      .then(() => {
        afterUpdate('Permissions updated successfully')
      })
      .catch((e) => {
        console.warn(e)
        afterUpdate('Error when updating solution permissions - refresh and try again.')
      })
  }
}

export const adminUpdateDataSourcePermissions = () => {
  return (dispatch, getState) => {
    dispatch(adminUpdatingDataSourcePermissions())
    const { dataSourceAccess, assignableRoles: { roleIds } } = getState().admin
    const afterUpdate = (message) => {
      dispatch(notify(message))
      dispatch(adminUpdatedDataSourcePermissions())
      dispatch(modalHide(constants.MODAL_DATA_SOURCE_SETTINGS))
    }

    const rolesToAdd = roleIds.filter((rid) => {
      const arr = dataSourceAccess.roles.filter(role => role.id === rid)
      return !(arr.length === 0)
    })

    const rolesToDelete = roleIds.filter(rid => rolesToAdd.indexOf(rid) < 0)

    Promise.all(
      [
        rolesToAdd.map(addId => postDataSourceRoleAccess(dataSourceAccess.name, addId)),
        rolesToDelete.map(removeId => deleteDataSourceRoleAccess(dataSourceAccess.name, removeId)),
      ],
    )
      .then(() => {
        afterUpdate('Permissions updated successfully')
      })
      .catch((e) => {
        console.warn(e)
        afterUpdate('Error when updating data source permissions - refresh and try again.')
      })
  }
}

const adminFetchingSystemAccess = () => ({
  type: ADMIN_FETCHING_SYSTEM_ACCESS,
})

const adminFetchedSystemAccess = access => ({
  type: ADMIN_FETCHED_SYSTEM_ACCESS,
  access,
})

export const adminFetchSystemAccess = () => {
  return (dispatch) => {
    dispatch(adminFetchingSystemAccess())
    getSystemAccess()
      .then(({ data }) => dispatch(adminFetchedSystemAccess(data)))
      .catch(() => {
        dispatch(adminFetchedSystemAccess([]))
        dispatch(notify('Error when getting system access', 'error'))
      })
  }
}

const adminFetchingBusinessUnits = () => ({
  type: ADMIN_FETCHING_BUSINESS_UNITS,
})

const adminFetchedBusinessUnits = businessUnits => ({
  type: ADMIN_FETCHED_BUSINESS_UNITS,
  businessUnits,
})

export const adminFetchBusinessUnits = () => {
  return (dispatch) => {
    dispatch(adminFetchingBusinessUnits())
    getBusinessUnits()
      .then(({ data }) => dispatch(adminFetchedBusinessUnits(data)))
      .catch(() => {
        dispatch(adminFetchedBusinessUnits([]))
        dispatch(notify('Error when getting business unit data', 'error'))
      })
  }
}

const adminUpdatingSystemAccess = () => ({
  type: ADMIN_UPDATING_SYSTEM_ACCESS,
})

const adminUpdatedSystemAccess = (access, isDelete) => ({
  type: ADMIN_UPDATED_SYSTEM_ACCESS,
  access,
  isDelete,
})

export const adminUpdateSystemAccess = (access, isDelete) => {
  return (dispatch) => {
    dispatch(adminUpdatingSystemAccess())

    const action = isDelete ? deleteSystemAccess : postSystemAccess

    action(access.country, access.businessUnit)
      .then(() => {
        dispatch(adminUpdatedSystemAccess(access, isDelete))
        dispatch(notify('System access updated'))
      })
      .catch(() => {
        dispatch(adminUpdatedSystemAccess(null, isDelete))
        dispatch(notify('Error when updating system access - refresh and try again', 'error'))
      })
  }
}

export const adminSelectBanner = banner => ({
  type: ADMIN_SELECT_BANNER,
  banner,
})

export const adminResetDefaultBanner = () => ({
  type: ADMIN_RESET_DEFAULT_BANNER,
})

export const adminUpdateActiveBanners = () => ({
  type: ADMIN_UPDATE_ACTIVE_BANNERS,
})

export const adminFetchingBanners = () => ({
  type: ADMIN_FETCHING_BANNERS,
})

export const adminFetchedBanners = banners => ({
  type: ADMIN_FETCHED_BANNERS,
  banners,
})

export const adminFetchedActiveBanners = banners => ({
  type: ADMIN_FETCHED_ACTIVE_BANNERS,
  banners,
})

export const adminUpdatingBanner = () => ({
  type: ADMIN_UPDATING_BANNER,
})

export const adminUpdatedBanner = banner => ({
  type: ADMIN_UPDATED_BANNER,
  banner,
})

export const adminResetBannerCTA = () => ({
  type: ADMIN_RESET_BANNER_CTA,
})

export const adminToggledBanner = bannerId => ({
  type: ADMIN_TOGGLED_BANNER,
  bannerId,
})

export const adminDeletedBanner = bannerId => ({
  type: ADMIN_DELETED_BANNER,
  bannerId,
})

export const adminFetchBanners = () => {
  return (dispatch) => {
    dispatch(adminFetchingBanners())
    getBanners()
      .then(({ data }) => dispatch(adminFetchedBanners(data)))
      .catch((e) => {
        dispatch(adminFetchedBanners([]))
        dispatch(notify('Error fetching banners', 'error'))
        console.error(e)
      })
  }
}

export const adminFetchActiveBanners = () => {
  return (dispatch) => {
    getActiveBanners()
      .then(({ data }) => dispatch(adminFetchedActiveBanners(data)))
      .catch((e) => {
        console.error(e)
      })
  }
}

export const adminSaveBanner = (banner, isEditing) => {
  return (dispatch) => {
    dispatch(adminUpdatingBanner())

    const { audienceCheck, ...bannerData } = banner
    bannerData.audience = Object.keys(audienceCheck)
      .filter(key => audienceCheck[key] === true)
      .map(Number)

    const callback = isEditing ? putBanner : postBanner
    callback(bannerData, bannerData.id)
      .then(({ data }) => {
        dispatch(adminUpdatedBanner(data))
        dispatch(adminUpdateActiveBanners())
        setTimeout(() => {
          dispatch(adminResetBannerCTA())
          dispatch(modalHide(constants.MODAL_BANNER_SETTINGS))
          dispatch(notify(`Successfully saved banner '${data.name}'`))
        }, 1000)
      })
      .catch((e) => {
        dispatch(adminResetBannerCTA())
        dispatch(notify('Error saving banner', 'error'))
        console.error(e)
      })
  }
}

export const adminToggleBanner = (banner) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(adminUpdatingBanner())

      const { bannerAccess: { activeBanners } } = getState().admin
      if (!banner.isActive && activeBanners.length >= 3) {
        dispatch(notify('Reached maximum banner limit, please deactivate another banner to proceed', 'error'))
      }
      patchToggleBanner(banner.id)
        .then(() => {
          dispatch(adminToggledBanner(banner.id))
          dispatch(adminUpdateActiveBanners())
          dispatch(notify(`Banner has been ${!banner.isActive ? 'activated' : 'deactivated'}`))
          resolve()
        })
        .catch((e) => {
          dispatch(adminResetBannerCTA())
          dispatch(notify('Error performing action, please try again', 'error'))
          console.error(e)
          reject(e)
        })
    })
  }
}

export const adminDeleteBanner = (banner) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(adminUpdatingBanner())

      deleteBanner(banner.id)
        .then(() => {
          dispatch(adminDeletedBanner(banner.id))
          dispatch(adminUpdateActiveBanners())
          dispatch(notify('Banner has been removed from list'))
          resolve()
        })
        .catch((e) => {
          dispatch(adminResetBannerCTA())
          dispatch(notify('Error performing action, please try again'))
          console.error(e)
          reject(e)
        })
    })
  }
}
