import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  getTagDetails,
  postTagComment,
  putTagLabels,
  putTag,
  getTags,
  getTagTasks,
} from 'actionHub/utils/actionHubApi'
import {
  tagCommentsUpdated,
  actionHubFetchedTagDetails,
  actionHubUpdateTagDescription,
  actionHubUpdateTagLabelValue,
  submittingTagLabels,
  submittedTagLabels,
  validateTagLabel,
  actionHubFetchedTags,
  actionHubFetchedTagTasks,
  actionHubFetchingTagTasks,
} from 'actionHub/redux/actions'
import useApp from 'hooks/useApp'

export default function useTag(actionSetId, tagId) {
  const dispatch = useDispatch()
  const { showNotification } = useApp()
  const jobId = useSelector(state => state.actionHub.actionSet?.jobId)

  const tag = useSelector(state => Object.values(state.actionHub.tags.tagsList).find(t => t.id === tagId)) || null
  const tagTasks = useSelector(state => (tag && tag.taskIds
    ? tag.taskIds.map(id => state.actionHub.tasks[id]).filter(t => t)
    : []))

  useEffect(() => {
    getTagTasks(actionSetId, tagId, '', 0)
      .then(({ data }) => {
        dispatch(actionHubFetchedTagTasks(actionSetId, tagId, data))
      })
      .catch((e) => {
        showNotification('Could not load tasks associated with this tag. Please refresh the page and contact support if issues persist.', e, 'error')
      })
  }, [actionSetId, dispatch, showNotification, tagId])

  useEffect(() => {
    getTagDetails(actionSetId, tagId)
      .then(({ data }) => {
        dispatch(actionHubFetchedTagDetails(tagId, data))
      })
      .catch((e) => {
        showNotification('Could not load tag details, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }, [dispatch, actionSetId, tagId, showNotification])

  const fetchNextPage = () => {
    const { _isFetching, pagination: { hasNext, page } } = tag.tagTasks
    if (hasNext && !_isFetching) {
      dispatch(actionHubFetchingTagTasks(actionSetId, tagId))

      getTagTasks(actionSetId, tagId, '', page + 1)
        .then(({ data }) => {
          dispatch(actionHubFetchedTagTasks(actionSetId, tagId, data))
        })
        .catch((e) => {
          showNotification('Could not load tasks associated with this tag. Please refresh the page and contact support if issues persist.', e, 'error')
        })
    }
  }

  const postCurrentTagComment = (comment) => {
    postTagComment(actionSetId, tagId, comment)
      .then(({ data }) => {
        const comments = tag.comments === null ? [data] : [...tag.comments, data]
        dispatch(tagCommentsUpdated(tagId, comments))
      })
  }

  const updateTagData = (tId, description, narrative, name) => {
    dispatch(actionHubUpdateTagDescription(actionSetId, tId, description, narrative, name))
  }

  const setLabelValue = (labelId, value) => {
    dispatch(actionHubUpdateTagLabelValue(actionSetId, tagId, labelId, value))
  }

  const validateLabel = (labelId, error = null) => {
    dispatch(validateTagLabel(actionSetId, tag.id, labelId, error))
  }

  const saveTagData = () => {
    putTag(actionSetId, null, tag.name, tag.description, tagId, tag.narrative)
      .then(() => {
        getTags(actionSetId)
          .then((tagsData) => {
            dispatch(actionHubFetchedTags(actionSetId, tagsData.data))
          })
      })
  }

  const submitTagLabels = () => {
    dispatch(submittingTagLabels(actionSetId, tag.id))
    putTagLabels(actionSetId, tag.id, tag.labels)
      .then(() => {
        dispatch(submittedTagLabels(actionSetId, tag.id, jobId, {}))
        showNotification('Review findings saved')
      })
      .catch((e) => {
        if (e.response && e.response.status === 400) {
          const errors = e.response.data.result.info.message
            .split(';')
            .map((error) => {
              const components = error.split('|')
              return {
                labelId: components[0],
                error: components[1],
              }
            })

          errors.forEach((error) => {
            dispatch(validateTagLabel(actionSetId, tag.id, error.labelId, error.error))
          })

          showNotification('Some values were invalid, please review the form and try again.', e, 'error')
        } else {
          showNotification('Could not save changes, please refresh the page and contact support if issues persist.', e, 'error')
        }
      })
  }

  return {
    updateTagData,
    saveTagData,
    postCurrentTagComment,
    tag,
    tagTasks,
    setLabelValue,
    submitTagLabels,
    validateLabel,
    fetchNextPage,
  }
}
