import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import classnames from 'classnames'
import moment from 'moment'
import TableInput from 'components/Form/components/TableInput'
import SelectRadioSwitchInput from 'components/Form/components/SelectRadioSwitchInput'
import ExternalLinkRenderer from 'components/ExternalLinkRenderer'
import {
  ToggleInput, TextField, DatePicker,
} from '@deloitte/gel-library'
import { getConfig } from 'utils/config'
import { isNullOrWhiteSpace } from 'utils/strings'
import usePrevious from 'hooks/usePrevious'
import styles from './Parameter.scss'

const propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  formData: PropTypes.shape({
    error: PropTypes.string,
    formKey: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ]),
  }).isRequired,
  index: PropTypes.number,
  liveValidation: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onValidation: PropTypes.func.isRequired,
  simple: PropTypes.bool,
}

const defaultProps = {
  className: null,
  dataTestId: '',
  disabled: false,
  index: null,
  onFocus: () => {},
  liveValidation: false,
  simple: false,
}

function Parameter({
  className,
  dataTestId, disabled, field,
  formData, index, onValidation,
  onChange, onFocus, simple, liveValidation,
}) {
  let Element
  let ElementType

  const previousValue = usePrevious(formData.value)

  useEffect(() => {
    if (liveValidation && !isNullOrWhiteSpace(formData.value) && formData.value !== previousValue) {
      const validateTimeout = setTimeout(() => {
        onValidation()
      }, 1000)

      return () => clearTimeout(validateTimeout)
    }

    return () => {}
  }, [previousValue, onValidation, formData.value, liveValidation])

  switch (field.type) {
    case 'BIT':
    case 'boolean':
      ElementType = 'ToggleInput'
      Element = (
        <div data-test-id={`toggleInput-${dataTestId}`}>
          <ToggleInput
            checked={formData.value === 1 || formData.value === 'true' || formData.value === true}
            className={className}
            data-test-id={`toggleInput-${dataTestId}`}
            disabled={disabled}
            label=""
            name={field.formKey}
            onChange={onChange}
          />
        </div>
      )
      break
    case 'COMBO BOX':
    case 'dropdown':
      ElementType = 'SelectRadioSwitchInput'
      Element = (
        <SelectRadioSwitchInput
          floatedLabel
          className={styles.inputfieldSelectInput}
          data-test-id={`selectRadioSwitchInput-${dataTestId}`}
          disabled={disabled}
          label=""
          name={field.formKey}
          onBlur={onValidation}
          onChange={onChange}
          options={field.options}
          searchable={false}
          value={formData.value}
        />
      )
      break
    case 'DATE':
    case 'date':
      ElementType = 'DateInput'
      Element = (
        <DatePicker
          className={className}
          data-test-id={`dateInput-${dataTestId}`}
          disabled={disabled}
          error={!isNullOrWhiteSpace(formData.error)}
          fullWidth={false}
          helperText={formData.error}
          label=""
          name={field.formKey}
          onChange={(date) => {
            onChange(field.formKey, moment(date).format('YYYY-MM-DD'))
          }}
          value={formData.value === '' ? null : formData.value}
        />
      )
      break
    case 'TABLE':
      ElementType = 'TableInput'
      Element = (
        <TableInput
          className={className}
          data-test-id={`tableInput-${dataTestId}`}
          disabled={disabled}
          error={formData.error}
          label=""
          name={field.formKey}
          onBlur={onValidation}
          onChange={onChange}
          onFocus={onFocus}
          schema={field.schema}
          value={formData.value}
        />
      )
      break
    case 'FLOAT':
    case 'float':
    case 'INT':
    case 'int':
    case 'BIGINT':
    case 'bigint':
      ElementType = 'NumberInput'
      Element = (
        <TextField
          fullWidth
          className={className}
          data-test-id={`numberInput-${dataTestId}`}
          defaultValue={`${formData.value}`}
          disabled={disabled}
          error={formData.error}
          label=""
          name={field.formKey}
          onBlur={onValidation}
          onChange={onChange}
          type="text"
          value={formData.value}
        />
      )
      break
    case 'MONEY':
    case 'money':
      ElementType = 'NumberInput'
      Element = (
        <TextField
          fullWidth
          adornment="$"
          className={className}
          data-test-id={`numberInput-${dataTestId}`}
          defaultValue={`${formData.value}`}
          disabled={disabled}
          error={formData.error}
          label=""
          name={field.formKey}
          onBlur={onValidation}
          onChange={onChange}
          type="text"
          value={formData.value}
        />
      )
      break
    default:
      ElementType = 'TextInput'
      Element = (
        <TextField
          fullWidth
          multiline
          className={className}
          data-test-id={`textInput-${dataTestId}`}
          defaultValue={formData.value}
          disabled={disabled}
          error={formData.error}
          label=""
          name={field.formKey}
          onBlur={onValidation}
          onChange={onChange}
          type="text"
          value={formData.value}
        />
      )
  }

  let Description = null

  if (field.description) {
    const description = field.description && field.description.replace(/\|VALUE\|/g, formData.value)
    const isDynamic = field.description && field.description.indexOf('|VALUE|') > -1
    const supportSiteLinkMarkdown = field.supportArticleId
      ? ` [Learn more](${getConfig().URL.SUPPORT}/${field.supportArticleId} "Further information about this parameter.")`
      : ''

    Description = (
      <div className={classnames(styles.staticDescription, { [styles.dynamicDescription]: isDynamic })}>
        <ReactMarkdown
          renderers={{ link: ExternalLinkRenderer }}
          source={`${description}${supportSiteLinkMarkdown}`}
        />
      </div>
    )
  }

  if (simple) {
    return (
      <div className={classnames(styles.inputfield, styles.simple)}>
        {Element}
        {Description}
      </div>
    )
  }

  return (
    <div>
      <div
        className={classnames(
          'row',
          styles.inputfield,
          styles[`inputfield${ElementType}`],
        )}
        key={index}
        style={{ marginLeft: 0, marginRight: 0, marginBottom: '34px' }}
      >
        <div className={field.type === 'TABLE' ? null : 'col-xs-12 col-sm-4 col-md-4'}>
          <p>{field.name}</p>
        </div>
        <div className={field.type === 'TABLE' ? null : 'col-xs-12 col-sm-8 col-md-8'}>
          <div>{Element}</div>
          {field.description && Description}
        </div>
      </div>
    </div>
  )
}

Parameter.propTypes = propTypes
Parameter.defaultProps = defaultProps

export default Parameter
