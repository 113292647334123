import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import { push } from 'connected-react-router'
import { Link } from 'react-router-dom'
import constants from 'constants'
import { checkIfSignedIn, getRequestUrl } from 'utils/api'
import map from 'lodash/map'
import find from 'lodash/find'
import some from 'lodash/some'
import every from 'lodash/every'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import Dialog from 'components/Dialog'
import Messagebar from 'components/Messagebar'
import FileUploader from 'components/FileUploader'
import FileUploaderModal from 'components/FileUploaderModal'
import { notify, redirect } from 'actions/app'
import { dialogCreate, dialogHide, dialogShow } from 'actions/dialogs'
import {
  upload,
  submit,
  resetCTA,
  startedDataValidation,
  finishedDataValidation,
  fetchJobStatus,
  startedUploadingFiles,
  endUploadingFiles,
  fetchDataRequirements,
} from 'actions/job'
import {
  updateUploader,
  setCurrentUploader,
  addFileError,
  resetFileError,
  removeFileError,
  addFile,
  updateFile,
  deleteFile,
  updateStatus,
} from 'actions/uploaders'
import { getConfig } from 'utils/config'
import { Button, Heading } from '@deloitte/gel-library'
import DataProcessingModal from 'components/DataProcessingModal'
import { getWorkflowStep, jobDataIsValidating } from 'utils/business/workflow'
import DataTips from 'views/DataTips/DataTips'
import { isRecipeSelected, jobHasErrors } from 'utils/business/jobs'
import { SUPPORT_PAGES, SUPPORT_SECTIONS } from 'constants/supportPages'
import { hot } from 'react-hot-loader/root'
import { objectToArray } from 'utils/objects'
import { getCookie } from 'utils/cookies'
import { devAuthToken } from 'constants/cookies'
import styles from './InputData.scss'

class InputData extends Component {
  static propTypes = {
    app: PropTypes.shape({
      user: PropTypes.shape({
        isExternal: PropTypes.bool,
      }),
    }).isRequired,
    dialogs: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleUploaderModal: PropTypes.func.isRequired,
    job: PropTypes.shape({
      _isCompletedJob: PropTypes.bool,
      _isSendingJob: PropTypes.bool,
      _isSettingParameters: PropTypes.bool,
      _isUploadingFiles: PropTypes.bool,
      _isValidatingData: PropTypes.bool,
      canRun: PropTypes.bool,
      dataValidation: PropTypes.string,
      jobId: PropTypes.string,
      noOfRuns: PropTypes.number,
      recipeName: PropTypes.string,
      recipes: PropTypes.arrayOf(PropTypes.shape({
        meta: PropTypes.shape({
          displayName: PropTypes.string,
        }),
        name: PropTypes.string,
      })),
      step: PropTypes.number,
      tables: PropTypes.arrayOf(PropTypes.shape({
        hasValidationException: PropTypes.bool,
      })),
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        jobId: PropTypes.string,
      }),
    }).isRequired,
    maximumStepAccess: PropTypes.number.isRequired,
    modals: PropTypes.object.isRequired,
    packages: PropTypes.object.isRequired,
    uploaders: PropTypes.shape({
      current: PropTypes.string,
      status: PropTypes.string,
      uploaders: PropTypes.object,
    }).isRequired,
  }

  state = {
    authCookie: null,
  }

  componentDidMount() {
    const { dispatch, job } = this.props
    dispatch(dialogCreate(constants.DIALOG_LEAVE_INPUTDATA))
    dispatch(dialogCreate(constants.DIALOG_REVERT_ALL_INPUTDATA))

    const authCookie = getCookie(devAuthToken)
    if (authCookie) {
      this.setState({ authCookie })
    }

    if (job.jobId) {
      dispatch(fetchDataRequirements(job.jobId))
    }

    if (jobDataIsValidating(job)) {
      dispatch(startedDataValidation())
    }
  }

  componentDidUpdate() {
    const {
      job: { jobId },
      uploaders: { uploaders, status },
      dispatch,
    } = this.props

    const requiredUploaders = Object.fromEntries(Object.entries(uploaders).filter(([, value]) => !value.isOptional))

    const isError = some(
      map(requiredUploaders, uploader => uploader.errors.length),
      count => count > 0,
    )

    switch (status) {
      case constants.UPLOAD_STATUS.UPLOADING:
        if (isError) {
          this.cancelUpload()
        } else {
          const isUploaded = every(
            map(requiredUploaders, (uploader) => {
              const newFileKeys = Object.keys(uploader.files).filter(
                k => uploader.files[k].status !== 'saved',
              )
              const existingFileKeys = Object.keys(uploader.files).filter(
                k => uploader.files[k].status === 'saved',
              )
              return {
                uploaded:
                  (newFileKeys.length > 0 || existingFileKeys.length > 0)
                  && newFileKeys.every(
                    fileKey => uploader.files[fileKey].status === 'uploaded',
                  ),
              }
            }),
            ['uploaded', true],
          )

          // If all files uploaded
          if (isUploaded) {
            dispatch(submit({ jobId }))
          }
        }
        break

      case constants.UPLOAD_STATUS.CANCELLED:
        dispatch(updateStatus(constants.UPLOAD_STATUS.NORMAL))
        dispatch(resetCTA())
        break

      default:
    }
  }

  validateFile = ({ size }) => {
    const valid = false
    if (
      size >= constants.UPLOAD_MAX_FILESIZE
    ) {
      return {
        valid,
        error: (
          <span>
            The file cannot be processed. Please delete this file and select a
            {' '}
            <a
              href={`/#${SUPPORT_PAGES.DATA_WRONG_FORMAT}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              supported file type
            </a>
            .
          </span>
        ),
      }
    }
    return { valid: true }
  }

  duplicateFileCheck = (index, name) => {
    const { uploaders } = this.props
    return find(
      filter(uploaders.uploaders[index].files, file => file.status !== 'saved'),
      { name },
    )
  }

  generateUploader = (index) => {
    const {
      app: { user: { isExternal } },
      match: {
        params: { jobId },
      },
      uploaders,
    } = this.props
    const { authCookie } = this.state
    const uploader = uploaders.uploaders[index]
    const tableCode = uploader.id

    const customHeaders = authCookie ? {
      Authorization: `Bearer ${authCookie}`,
    } : null

    return new FineUploaderTraditional({
      options: {
        autoUpload: false,
        request: {
          endpoint: getRequestUrl(`${isExternal ? '/client' : ''}/file/uploadfile`),
          params: {
            jobId,
            tableCode,
          },
          customHeaders,
        },
        callbacks: {
          onSubmitted: (fileId, name) => {
            if (name.includes('#')) {
              this.addFileError({
                name,
                status: 'error',
                error: `The file ${name} contains a hash character (#) in its filename, which is not supported. Please delete it, rename the file to remove the #, and upload again.`,
              })
            } else if (this.duplicateFileCheck(index, name)) {
              this.addFileError({
                name,
                status: 'error',
                error: `The file ${name} already exists. You can only have a single file with the same name. Please delete it and upload again.`,
              })
            } else {
              this.addFile({
                fileId,
                status: 'submitted',
                name,
                rows: [],
                tableHeaders: [],
              })
            }
          },
          onUpload: (fileId, name) => {
            this.updateFile(
              {
                fileId,
                status: 'uploading',
                name,
              },
              tableCode,
            )
          },
          onCancel: (fileId) => {
            this.deleteFile(
              {
                fileId,
              },
              tableCode,
            )
          },
          onComplete: (fileId, name, resp) => {
            if (resp.success) {
              this.updateFile(
                {
                  fileId,
                  status: 'uploaded',
                  name,
                },
                tableCode,
              )
            }
          },
          onError: (fileId, name, errorReason, xhr) => {
            if (
              errorReason.includes(
                'has an invalid extension. Valid extension(s):',
              )
            ) {
              return
            }
            if (errorReason.includes('is too large, maximum file size is ')) {
              return
            }
            if (errorReason.includes('No files to upload.')) {
              return
            }

            // Server error
            if (xhr) {
              this.handleServeError()
            } else {
              this.addFileError(
                {
                  error: `Failed to upload. Please delete and re-select (${errorReason})`,
                  status: 'error',
                  name,
                },
                tableCode,
              )

              this.deleteFile(
                {
                  fileId,
                },
                tableCode,
              )
            }
          },
          onValidateBatch: (data) => {
            data.forEach((item) => {
              const valid = this.validateFile(item)
              if (!valid.valid) {
                this.addFileError({
                  error: valid.error,
                  status: 'error',
                  name: item.name,
                })
              }
            })
          },
        },
        chunking: {
          enabled: true,
          success: {
            endpoint: getRequestUrl(`${isExternal ? '/client' : ''}/file/uploadfilecomplete`),
            params: {
              jobId,
              tableCode,
            },
            customHeaders,
          },
          concurrent: {
            enabled: true,
          },
        },
        cors: {
          expected: true,
          sendCredentials: true,
        },
        validation: {
          sizeLimit: constants.UPLOAD_MAX_FILESIZE,
        },
      },
    })
  }

  handleServeError = () => {
    // Generate errors for all files and remove all files
    const { uploaders, dispatch } = this.props
    forEach(uploaders.uploaders, ({ id, files }) => {
      forEach(
        filter(files, ({ status }) => status !== 'uploaded'),
        ({ name, fileId }) => {
          this.addFileError(
            {
              error: 'Failed to upload. Please refresh and try again, and contact support if issues persist.',
              status: 'error',
              name,
            },
            id,
          )

          this.deleteFile(
            {
              fileId,
            },
            id,
          )
        },
      )
    })

    dispatch(finishedDataValidation())
    dispatch(endUploadingFiles())
  }

  openModal = (index) => {
    const { dispatch, handleUploaderModal, uploaders } = this.props
    const uploaderKey = `uploader_${index}`
    const { [uploaderKey]: uploader } = this.state

    if (!uploader) {
      this.setState({
        [uploaderKey]: this.generateUploader(index),
      })
    }
    forEach(
      filter(uploaders.uploaders[index].files, ['status', 'pending']),
      ({ fileId }) => {
        this.updateFile({
          fileId,
          status: 'parsed',
        }, index)
      },
    )

    dispatch(setCurrentUploader(index))
    handleUploaderModal('Show')
  }

  cancelUpload = () => {
    const { uploaders, dispatch } = this.props
    // Cancel all uploding
    forEach(uploaders.uploaders, (uploader, index) => {
      const { [`uploader_${index}`]: stateUploader } = this.state
      if (stateUploader) {
        stateUploader.methods.cancelAll()
      }
    })

    dispatch(updateStatus(constants.UPLOAD_STATUS.CANCELLED))
  }

  handleFileUpdate = (file, id) => {
    const { dispatch } = this.props
    dispatch(updateUploader(file, id))
  }

  addFile = (file) => {
    const { dispatch } = this.props
    dispatch(addFile(file))
  }

  updateFile = (file, uploaderId) => {
    const { dispatch } = this.props
    dispatch(updateFile(file, uploaderId))
  }

  deleteFile = (fileId, uploaderId) => {
    const { dispatch } = this.props
    dispatch(deleteFile(fileId, uploaderId))
  }

  addFileError = (error, id) => {
    const { dispatch } = this.props
    dispatch(addFileError(error, id))
  }

  resetFileError = () => {
    const { dispatch } = this.props
    dispatch(resetFileError())
  }

  removeFileError = (index) => {
    const { dispatch } = this.props
    dispatch(removeFileError(index))
  }

  startUpload = () => {
    const {
      dispatch,
      uploaders,
    } = this.props

    checkIfSignedIn()
      .then(() => {
        const uploaderUIs = map(uploaders.uploaders, (uploader, index) => {
          const pendingFiles = some(uploader.files, x => x.status === 'pending')
          if (!pendingFiles) { return null }

          const { [`uploader_${index}`]: uploaderKey } = this.state
          return uploaderKey
        }).filter(x => x)

        dispatch(startedUploadingFiles())
        dispatch(startedDataValidation())

        dispatch(upload(uploaderUIs))
      })
      .catch(() => {
        dispatch(notify('Your session has expired due to inactivity. You will need to refresh the page, sign in and re-upload your files.', 'error', 100000))
      })
  }

  undoChanges = () => {
    const { uploaders, dispatch } = this.props
    forEach(uploaders.uploaders, (uploader, index) => {
      dispatch(setCurrentUploader(index))
      this.removeFilesForUploader(uploader)
    })
  }

  removeFilesForUploader = (uploader, showConfirmation = false) => {
    const { dispatch } = this.props

    const uploadedFiles = filter(
      uploader.files,
      file => file.status !== 'saved',
    )

    if (showConfirmation && uploadedFiles.length > 0) {
      dispatch(
        dialogShow(`${constants.DIALOG_REVERT_INPUTDATA}_${uploader.id}`),
      )
    }

    forEach(uploadedFiles, (file) => {
      dispatch(deleteFile(file, uploader.id))
    })
  }

  renderUploader = (uploader, index) => {
    const revertDialogKey = `${constants.DIALOG_REVERT_INPUTDATA}_${
      uploader.id
    }`
    const {
      dispatch,
      job,
      packages,
      dialogs: { [revertDialogKey]: dialog },
    } = this.props

    return (
      <div data-id={index} key={index}>
        <FileUploader
          cancel={this.cancelUpload}
          createRevertDialog={() => dispatch(dialogCreate(revertDialogKey))}
          disabled={job._isSendingJob}
          hideRevertDialog={() => dispatch(dialogHide(revertDialogKey))}
          hideSavedFiles={job.tables.some(x => x.hasValidationException)}
          index={index}
          job={job}
          openModal={() => this.openModal(index)}
          packageInputTableMeta={packages.packages[job.packageId].inputTables.find(it => it.displayName === uploader.name)}
          // packageInputTableMeta={packages.packages[job.packageId].inputTables.find(it => it.name === uploader.id)}
          revertDialog={dialog}
          revertUploads={() => this.removeFilesForUploader(uploader, true)}
          showRevertDialog={() => dispatch(dialogShow(revertDialogKey))}
          uploader={uploader}
        />
      </div>
    )
  }

  render() {
    const {
      match: {
        params: { jobId },
      },
      app: {
        user: { isExternal },
      },
      job,
      modals,
      uploaders,
      handleUploaderModal,
      dialogs,
      dispatch,
      maximumStepAccess,
    } = this.props
    const isVerify = job.noOfRuns > 0
    const { [`uploader_${uploaders.current}`]: currentUploader } = this.state
    const { SUPPORT_EMAIL: supportEmail } = getConfig()

    const requiredUploaders = Object.fromEntries(Object.entries(uploaders.uploaders).filter(([, value]) => !value.isOptional))

    const mapOfuploadersAndFiles = map(requiredUploaders, (uploader) => {
      return filter(
        uploader.files,
        file => file.status === 'pending' || file.status === 'saved',
      ).length
    })

    const jobIsMissingFiles = some(
      mapOfuploadersAndFiles,
      count => count < 1,
    )

    const fixDataErrorsLink = `/#${SUPPORT_SECTIONS.DATA}`

    const newFilesPending = map(uploaders.uploaders, (uploader) => {
      return filter(
        uploader.files,
        file => file.status === 'pending',
      ).length
    }).some(count => count > 0)

    const uploadingCount = map(uploaders.uploaders, (uploader) => {
      return filter(
        uploader.files,
        file => file.status === 'pending' || file.status === 'uploading' || file.status === 'uploaded',
      ).length
    }).reduce((a, b) => a + b, 0)

    const showDialog = (dialogs
        && dialogs[constants.DIALOG_LEAVE_INPUTDATA]
        && dialogs[constants.DIALOG_LEAVE_INPUTDATA].show)
      || false
    const showRevertAllDialog = (dialogs
        && dialogs[constants.DIALOG_REVERT_ALL_INPUTDATA]
        && dialogs[constants.DIALOG_REVERT_ALL_INPUTDATA].show)
      || false

    const hasValidationExceptions = job.tables.some(x => x.hasValidationException)

    // Prevent replacing just one of a collection of files required by a recipe
    const uploaderArray = objectToArray(uploaders.uploaders)
    const savedDataRecipeData = uploaderArray
      .filter(u => u.dataRecipe && objectToArray(u.files).some(f => f.status === 'saved'))
    const selectedDataRecipeData = uploaderArray
      .filter(u => u.dataRecipe && objectToArray(u.files).some(f => f.status === 'pending'))
    const recipeBeingReplaced = selectedDataRecipeData.length > 0
      ? job.recipes.find(r => r.name === selectedDataRecipeData[0].dataRecipe)
      : null

    const hasSomeButNotAllRecipeDataBeenSelected = savedDataRecipeData.length > 0
      && selectedDataRecipeData.length > 0
      && savedDataRecipeData.length > selectedDataRecipeData.length

    const dialogProps = {
      actions: [
        {
          label: 'STAY',
          onClick: () => {
            dispatch(dialogHide(constants.DIALOG_LEAVE_INPUTDATA))
          },
        },
        {
          label: 'LEAVE',
          onClick: () => {
            dispatch(dialogHide(constants.DIALOG_LEAVE_INPUTDATA))
            dispatch(
              push(dialogs[constants.DIALOG_LEAVE_INPUTDATA].options.path),
            )
          },
          primary: true,
        },
      ],
      className: styles.dialog,
      active: showDialog,
      onClose: () => {
        dispatch(dialogHide(constants.DIALOG_LEAVE_INPUTDATA))
      },
      title: 'Are you sure you want to leave this step?',
    }

    const revertAllDialogProps = {
      actions: [
        {
          label: 'CANCEL',
          onClick: () => {
            dispatch(dialogHide(constants.DIALOG_REVERT_ALL_INPUTDATA))
          },
        },
        {
          label: 'REVERT ALL',
          onClick: () => {
            dispatch(dialogHide(constants.DIALOG_REVERT_ALL_INPUTDATA))
            this.undoChanges()
          },
          primary: true,
        },
      ],
      className: styles.dialog,
      active: showRevertAllDialog,
      onClose: () => {
        dispatch(dialogHide(constants.DIALOG_REVERT_ALL_INPUTDATA))
      },
      title: 'Revert all to original?',
    }

    return (
      <div className={styles.base}>
        <Heading className={styles.title} level={4}>
          Input data
        </Heading>

        {hasValidationExceptions && (
          <Messagebar className={styles.messageBar} type="error">
            <p className={styles.messageBarHeader}>Failed to upload data</p>
            <p>
              Your data could not be uploaded due to a system error. Pease try again. If problem persists, contact
              {' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              {' '}
              for help.
            </p>
          </Messagebar>
        )}

        <DataTips />

        {isVerify
          && jobHasErrors(job) && (
            <Messagebar type="warn">
              Errors were found in your previously-uploaded data and may skew the analysis. Please refer to the
              {' '}
              <Link to={`/job/${jobId}/errors`}>Error Summary</Link>
              {' '}
              for details.
              {' '}
              <a
                href={fixDataErrorsLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn how to fix common data errors
              </a>
              , before re-processing your data.
            </Messagebar>
        )}
        {map(uploaders.uploaders, this.renderUploader)}

        {hasSomeButNotAllRecipeDataBeenSelected && recipeBeingReplaced && (
          <Messagebar className={styles.messageBar} type="warn">
            <p>
              When replacing
              {' '}
              {recipeBeingReplaced.meta.displayName}
              {' '}
              files, please replace
              {' '}
              <strong>all</strong>
              {' '}
              files to proceed
            </p>
          </Messagebar>
        )}

        <div className={styles.footer}>
          <Button
            mode="flat"
            onClick={() => {
              dispatch(redirect(`/create/${jobId}/getdata`))
            }}
          >
            back
          </Button>
          <div>
            {isVerify
              && newFilesPending && (
                <Button
                  mode="flat"
                  onClick={() => dispatch(dialogShow(constants.DIALOG_REVERT_ALL_INPUTDATA))
                  }
                >
                  UNDO CHANGES
                </Button>
            )}

            {maximumStepAccess > 4 && !newFilesPending
              ? (
                <Button
                  className={styles.CTA}
                  onClick={() => dispatch(push(getWorkflowStep(5, jobId, isExternal).to))}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  className={styles.CTA}
                  disabled={!job.canRun
                    || jobIsMissingFiles
                    || !newFilesPending
                    || hasSomeButNotAllRecipeDataBeenSelected}
                  onClick={this.startUpload}
                >
                  {isVerify ? 'RE-SUBMIT DATA' : 'PROCESS DATA'}
                </Button>
              )}

          </div>
        </div>
        <FileUploaderModal
          addFile={this.addFile}
          addFileError={this.addFileError}
          deleteFile={this.deleteFile}
          handleContinue={() => handleUploaderModal('Hide')}
          handleUploaderModal={handleUploaderModal}
          isExternal={isExternal}
          job={job}
          modals={modals}
          removeFileError={this.removeFileError}
          resetFileError={this.resetFileError}
          updateFile={this.updateFile}
          uploader={uploaders.uploaders[uploaders.current]}
          uploaderUI={currentUploader}
        />
        <Dialog {...dialogProps}>
          <p>
            If you leave, your pending data will not be saved unless you process your data or save.
          </p>
        </Dialog>
        <Dialog {...revertAllDialogProps}>
          <p>Any new data files will be removed.</p>
        </Dialog>
        {job._isValidatingData && (
          <DataProcessingModal
            active={job._isValidatingData}
            checkJob={() => dispatch(fetchJobStatus({ jobId }))}
            fileCount={uploadingCount}
            homeAction={() => dispatch(push('/home'))}
            isRecipeSelected={isRecipeSelected(job.recipeName)}
            isUploading={job._isUploadingFiles}
          />
        )}
      </div>
    )
  }
}

export default hot(InputData)
