import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import IconPriorityHigh from 'icons/IconPriorityHigh'
import IconPriorityLow from 'icons/IconPriorityLow'
import IconPriorityMedium from 'icons/IconPriorityMedium'
import styles from './Priority.scss'

function Priority({ priority, showDescription }) {
  let Icon

  switch (priority.id) {
    case 0:
      Icon = IconPriorityLow
      break
    case 1:
      Icon = IconPriorityMedium
      break
    default:
      Icon = IconPriorityHigh
      break
  }

  return (
    <div className={classNames(styles.base, styles[`priority_${priority.id}`])}>
      <Icon />
      {showDescription && <span className={styles.description}>{priority.description}</span>}
    </div>
  )
}

Priority.propTypes = {
  priority: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
  }),
  showDescription: PropTypes.bool,
}

Priority.defaultProps = {
  showDescription: false,
  priority: {
    id: 0,
    description: 'Low',
  },
}

export default Priority

