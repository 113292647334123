import { push } from 'connected-react-router'
import {
  getGenAiAppById,
  getGenAiApps,
  getModels,
  postGenAiApp,
  putGenAiApp,
  getAiFilesById,
  postAiWebsite,
} from 'utils/api/genAi'
import { notify } from 'actions/app'
import { modalHide } from 'actions/modals'
import constants from 'constants'
import {
  FETCHED_APPS,
  FETCHED_CURRENT_APP,
  FETCHING_APPS,
  FETCHING_CURRENT_APP,
  INITIALISE_APP_CREATION,
  RESET_SELECTED_TEMPLATE,
  UPDATE_APP_ADVANCED_SETTINGS_FORM,
  UPDATE_APP_DETAILS_FORM,
  UPDATE_APP_FULL_FORM,
  UPDATE_APP_KNOWLEDGE_BASE_FORM,
  UPDATE_APP_MODELS,
  UPDATE_APP_PROMPTS_FORM,
  UPDATE_SELECTED_TEMPLATE,
  UPDATE_FILES,
  FETCHING_FILES,
  FETCHED_FILES,
  SUBMITTING_FILE,
  SUBMITTING_WEBSITE,
  FETCHING_WEBSITES,
  UPDATE_WEBSITES,
  UPDATE_PERCENT,
  SHOW_DUPLICATE_DIALOG,
  COMPLETE_FILE_UPLOAD,
  COMPLETE_WEBSITE_UPLOAD,
  UPSERT_APP_DETAILS,
} from './actionTypes'

export const showDuplicateDialog = () => ({
  type: SHOW_DUPLICATE_DIALOG,
})

export const submittingFile = filename => ({
  type: SUBMITTING_FILE,
  payload: filename,
})

export const completeFileUpload = filename => ({
  type: COMPLETE_FILE_UPLOAD,
  payload: filename,
})

export const completeWebUpload = website => ({
  type: COMPLETE_WEBSITE_UPLOAD,
  payload: website,
})

export const submittingWebsite = website => ({
  type: SUBMITTING_WEBSITE,
  payload: website,
})

export const uploadWebsite = (websitePayload) => {
  return (dispatch) => {
    dispatch(submittingWebsite({ website: websitePayload.website }))
    const formData = new FormData()
    Object.entries(websitePayload).forEach(([key, value]) => {
      formData.append(key, value)
    })
    postAiWebsite(formData)
      .then((response) => {
        dispatch(completeWebUpload({ website: websitePayload.website }))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(notify('Error when fetching website for this GenApp - please refresh and try again', 'error'))
        }
      })
  }
}

export const submittingFileWithoutDuplicate = (filename) => {
  return (dispatch, getState) => {
    // const files = getState().genAi.aiApp.knowledge.files;
    // if (filename in files) {
    //   dispatch(showDuplicateDialog());
    // } else {
    dispatch(submittingFile(filename))
    // }
  }
}

export const fetchingFiles = fetching => ({
  type: FETCHING_FILES,
  payload: fetching,
})

export const updateFiles = files => ({
  type: UPDATE_FILES,
  payload: files,
})

export const updatePercent = percent => ({
  type: UPDATE_PERCENT,
  payload: percent,
})

export const updateWebsites = websites => ({
  type: UPDATE_WEBSITES,
  payload: websites,
})

export function fetchingFilesWebsById(appId) {
  return (dispatch) => {
    dispatch(fetchingFiles(true))
    getAiFilesById(appId)
      .then((response) => {
        const nonHtmlFiles = {}
        const htmlFiles = {}
        Object.entries(response.data).forEach(([key, value]) => {
          if (key.endsWith('.html')) {
            htmlFiles[key] = value
          } else {
            nonHtmlFiles[key] = value
          }
        })
        dispatch(updateFiles(nonHtmlFiles))
        dispatch(updateWebsites(htmlFiles))

        dispatch(fetchingFiles(false))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(notify('Error when fetching files for this GenApp - please refresh and try again', 'error'))
        }
      })
  }
}

export const fetchingWebsites = fetching => ({
  type: FETCHING_WEBSITES,
  payload: fetching,
})

export const fetchedFiles = files => ({
  type: FETCHED_FILES,
  payload: files,
})

export const updateSelectedTemplate = template => ({
  type: UPDATE_SELECTED_TEMPLATE,
  payload: template,
})

export const fetchingApp = fetching => ({
  type: FETCHING_CURRENT_APP,
  payload: fetching,
})

export const GetCurrentApp = () => ({
  type: FETCHED_CURRENT_APP,
})

export const fetchingApps = fetching => ({
  type: FETCHING_APPS,
  payload: fetching,
})

export const fetchedApps = fetching => ({
  type: FETCHED_APPS,
  payload: fetching,
})

export const resetSelectedTemplate = () => ({
  type: RESET_SELECTED_TEMPLATE,
})

export const updateAppDetailsForm = fields => ({
  type: UPDATE_APP_DETAILS_FORM,
  payload: fields,
})
export const updateappPromptsForm = fields => ({
  type: UPDATE_APP_PROMPTS_FORM,
  payload: fields,
})
export const updateAppKnowledgeBaseForm = fields => ({
  type: UPDATE_APP_KNOWLEDGE_BASE_FORM,
  payload: fields,
})
export const updateAppadvancedSettingsForm = fields => ({
  type: UPDATE_APP_ADVANCED_SETTINGS_FORM,
  payload: fields,
})

export const updateFullForm = fields => ({
  type: UPDATE_APP_FULL_FORM,
  payload: fields,
})

export const initialiseAppCreation = () => ({
  type: INITIALISE_APP_CREATION,
})

export const upsertGenAppDetails = (fields, appId, collection) => ({
  type: UPSERT_APP_DETAILS,
  payload: fields,
  appId,
  collection,
})

export const updateModels = () => ({
  type: UPDATE_APP_MODELS,
})

export function getApps() {
  return (dispatch) => {
    dispatch(fetchingApps(true))
    getGenAiApps()
      .then((response) => {
        const formattedResponse = {}
        Object.keys(response.data).forEach((key) => {
          formattedResponse[key.toLowerCase()] = response.data[key]
        })
        dispatch(fetchingApps(false))
        dispatch(fetchedApps(formattedResponse))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(notify('Error when fetching app - please refresh and try again', 'error'))
        }
      })
  }
}

export function generateApp() {
  return (dispatch, getState) => {
    const currentState = getState()
    const genAppData = currentState.genAi.aiApp
    const createAppDto = {
      description: genAppData.appConfig.description,
    }
    postGenAiApp(createAppDto)
      .then((response) => {
        dispatch(updateFullForm(response.data))
        dispatch(push(`/editapp/${response.data.fullAppInfo.appConfig.appId}`))
        dispatch(notify(`App ${response.data.fullAppInfo.appConfig.name} has been created successfully`))
        dispatch(getApps())
        dispatch(modalHide(constants.MODAL_GEN_AI_TEMPLATE_SELECT))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(notify('Error when creating app - please refresh and try again'))
        }
      })
  }
}

export function getAppById(appId) {
  return (dispatch) => {
    dispatch(fetchingApp(true))
    getGenAiAppById(appId)
      .then((response) => {
        dispatch(updateFullForm(response.data))
        dispatch(fetchingApp(false))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(notify('Error when fetching app - please refresh and try again'))
        }
      })
  }
}

export function putApp() {
  return (dispatch, getState) => {
    const currentState = getState()
    const genAppData = currentState.genAi.aiApp
    const genAppDto = {
      fullAppInfo: {
        appConfig: genAppData.appConfig,
        promptConfig: genAppData.promptConfig,
        knowledgeBase: genAppData.knowledgeBase,
        advancedModelSettings: genAppData.advancedModelSettings,
        userInfo: genAppData.userInfo,
      },
      modelTypes: currentState.genAi.modelTypes,
    }
    dispatch(fetchingApp(true))
    putGenAiApp(genAppData.appConfig.appId, genAppDto)
      .then((response) => {
        dispatch(updateFullForm(response.data))
        dispatch(fetchingApp(false))
        dispatch(upsertGenAppDetails(response.data.fullAppInfo, response.data.fullAppInfo.appConfig.appId, response.data.fullAppInfo.appConfig.collection.toLowerCase()))
        dispatch(notify(`App ${response.data.fullAppInfo.appConfig.name} has been updated successfully`))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(notify('Error when updating app - please refresh and try again'))
        }
      })
  }
}

export function fetchModels() {
  return (dispatch) => {
    getModels()
      .then((response) => {
        dispatch(updateModels(response.data))
      })
  }
}
