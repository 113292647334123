import React, {
  useRef, useEffect, useState, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { DataTableSimple } from '@deloitte/gel-library'
import { userPropType, priorityPropType } from 'utils/propTypes'
import DataItem from 'actionHub/components/DataItem'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import { sortByFunction } from 'utils/arrays'
import { useDispatch, useSelector } from 'react-redux'
import useInfiniteScroll from 'hooks/useInfiniteScroll'
import { updateTaskListScrollLocation } from 'actionHub/redux/actions'
import Loading from 'components/Loading'
import ActionBar from '../ActionBar/ActionBar'

import styles from './TaskList.scss'

const systemColumnPriority = [
  {
    name: 'priorityId',
    displayName: <PriorityHighIcon />,
    isSystem: true,
    className: styles.priorityColumn,
  },
]

const systemColumnAction = [
  {
    name: 'taskActionId',
    displayName: 'Action',
    isSystem: true,
    className: styles.globalColumns,
  },
]

const systemColumnStatus = [
  {
    name: 'statusId',
    displayName: 'Status',
    isSystem: true,
    className: styles.globalColumns,
  },
]

const systemColumnAssignedTo = [
  {
    name: 'assignedTo',
    displayName: 'Practitioner',
    isSystem: true,
  },
]

const systemColumnClientTeam = [
  {
    name: 'clientTeamId',
    displayName: 'Client',
    isSystem: true,
  },
]

const systemColumnReviewer = [
  {
    name: 'reviewerId',
    displayName: 'Reviewer',
    isSystem: true,
  },
]

const systemColumnTag = [
  {
    name: 'tagId',
    displayName: 'Tickmark',
    isSystem: true,
  },
]

const systemColumnComments = [
  {
    name: 'commentStatusId',
    displayName: 'Comments',
    isSystem: true,
  },
]

function TaskList({
  tasks, disabled, columns, statuses, members, priorities, actionSetId,
  setTasksAssigned, setTasksPriority, setTasksActionState, showTags, onLoadMore,
  selectedTasks, setSelectedTasks, onHandleOpenModal, actionStates, clients,
  hideTaskMeta,
}) {
  const dispatch = useDispatch()
  const { tagsList } = useSelector(state => state.actionHub.tags)
  const { hasExpired } = useSelector(state => state.job)
  const storedScrollPosition = useSelector(state => state.actionHub.taskListScrollLocation)
  const { _isAddingTask } = useSelector(state => state.actionHub)
  const wrapperRef = useRef(null)
  const taskListRef = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const commentStatuses = useSelector(state => state.actionHub.commentStatuses)
  const { allowClientComments = false } = useSelector(state => state.actionHub.taskLevel)

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        setScrollPosition(wrapperRef.current.scrollTop)
      }
    }

    const scrollableElement = wrapperRef.current
    scrollableElement.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (typeof storedScrollPosition !== 'undefined' && wrapperRef.current) {
      wrapperRef.current.scrollTop = storedScrollPosition
    }
  }, [storedScrollPosition])

  useInfiniteScroll(wrapperRef, taskListRef, onLoadMore)

  const taskMetaColumns = !hideTaskMeta
    ? [
      ...systemColumnAssignedTo,
      ...(allowClientComments ? systemColumnClientTeam : []),
      ...(allowClientComments ? systemColumnReviewer : []),
      ...(allowClientComments ? systemColumnComments : []),
      ...systemColumnAction,
      ...systemColumnStatus,
    ] : []

  const dataColumns = [
    ...(!hideTaskMeta ? systemColumnPriority : []),
    ...columns,
    ...(showTags ? systemColumnTag : []),
    ...taskMetaColumns,
  ].map(x => ({
    cellExtractor: row => (
      <DataItem
        isTaskList
        actionStates={actionStates}
        clients={clients}
        column={x}
        commentStatuses={commentStatuses}
        members={members}
        priorities={priorities}
        statuses={statuses}
        tagsList={tagsList}
        value={x.isSystem ? row[x.name] : row.taskData[x.name]}
      />
    ),
    heading: x.displayName,
    key: x.name,
    className: x.name === 'Short description' ? styles.shortDesc : x.className,
  }))

  const columnsWithSpacer = [
    {
      cellExtractor: () => '',
      heading: '',
      key: '_',
      className: styles.checkboxCells,
    },
    ...dataColumns,
  ]

  const handleSelection = (taskId) => {
    setSelectedTasks(taskIds => (taskIds.includes(taskId)
      ? taskIds.filter(x => x !== taskId)
      : [...taskIds, taskId]))
  }

  const handleSelectAll = (taskSelections) => {
    const ids = taskSelections.map(x => x._id)

    if (ids.every(id => selectedTasks.includes(id))) {
      setSelectedTasks(taskIds => taskIds.filter(x => !ids.includes(x)))
    } else {
      setSelectedTasks(taskIds => [...new Set([...taskIds, ...ids])])
    }
  }

  const deSelectAll = () => {
    setSelectedTasks([])
  }

  const calcScrollPositionAndOpenModal = useCallback((selectedRow) => {
    dispatch(updateTaskListScrollLocation(scrollPosition))
    onHandleOpenModal(false, selectedRow._id)
  }, [dispatch, onHandleOpenModal, scrollPosition])

  return (
    <div className={styles.base}>
      <ActionBar
        actionSetId={actionSetId}
        actionStates={actionStates}
        clients={clients}
        deSelectAll={deSelectAll}
        isVisible={selectedTasks.length > 0}
        members={members}
        onHandleOpenModal={onHandleOpenModal}
        priorities={priorities}
        selectedTaskIds={selectedTasks}
        setTasksActionState={setTasksActionState}
        setTasksAssigned={setTasksAssigned}
        setTasksPriority={setTasksPriority}
      />
      <div className={styles.tableWrapper} ref={wrapperRef}>
        {_isAddingTask
          ? (
            <div className={styles.loadingIndicator}>
              <Loading pageLoading />
            </div>
          )
          : (
            <DataTableSimple
              clickable
              chevronClassName={styles.chevronCells}
              className={styles.table}
              columns={disabled ? columnsWithSpacer : dataColumns}
              data={tasks}
              disabled={hasExpired}
              keyExtractor={x => x._id}
              onRowClick={x => calcScrollPositionAndOpenModal(x)}
              onSelect={x => handleSelection(x._id)}
              onSelectAll={handleSelectAll}
              ref={taskListRef}
              selectClassName={styles.checkboxCells}
              selectable={!disabled}
              selectedExtractor={x => selectedTasks.includes(x._id)}
              sortFunction={sortByFunction(x => x.value)}
              spacing="compact"
            />
          )
      }

      </div>
    </div>
  )
}

TaskList.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  actionStates: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
  })).isRequired,
  clients: PropTypes.arrayOf(userPropType).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  hideTaskMeta: PropTypes.bool,
  members: PropTypes.arrayOf(userPropType).isRequired,
  onHandleOpenModal: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  priorities: PropTypes.arrayOf(priorityPropType).isRequired,
  selectedTasks: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedTasks: PropTypes.func.isRequired,
  setTasksActionState: PropTypes.func.isRequired,
  setTasksAssigned: PropTypes.func.isRequired,
  setTasksPriority: PropTypes.func.isRequired,
  showTags: PropTypes.bool.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
  })).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    assignedTo: PropTypes.string,
    statusId: PropTypes.number,
    taskActionId: PropTypes.number,
  })).isRequired,
}

TaskList.defaultProps = {
  disabled: false,
  hideTaskMeta: false,
}

export default TaskList
