import React, {
  Fragment, useEffect, useState,
} from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/layout/Grid/'
import constants from 'constants'
import { connect } from 'react-redux'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import { withRouter, Switch } from 'react-router-dom'
import ManageJobModal from 'components/ManageJobModal'
import {
  fetchCurrentActions,
  fetchJobDetails, fetchReports, removeMember, updateMembers,
} from 'actions/job'
import { modalShow } from 'actions/modals'
import Loading from 'components/Loading'
import AddExternalUserModal from 'components/AddExternalUserModal'
import JobTitleBanner from 'components/JobTitleBanner'
import JobSummaryBanner from 'components/JobSummaryBanner'
import { updateExternalUsers } from 'actions/forms/addExternalUsers'
import { isManagerOrPartnerOnJob } from 'utils/business/jobs'
import { canAccessActionHub, canInviteClientUsers } from 'utils/permissions'
import { hot } from 'react-hot-loader/root'
import { push } from 'connected-react-router'
import EditDataWarningDialog from 'views/Job/components/EditDataWarningDialog'
import { fetchValidationsSummary } from 'actions/validations'

const propTypes = {
  app: PropTypes.shape({
    user: PropTypes.shape({
      _isFetched: PropTypes.bool,
      firstName: PropTypes.string,
      isAdmin: PropTypes.bool,
      isAuOffice: PropTypes.bool,
      isExternal: PropTypes.bool,
      username: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  job: PropTypes.shape({
    _isFetchedExternalRoles: PropTypes.bool,
    _isFetching: PropTypes.bool,
    _isFetchingJob: PropTypes.bool,
    actions: PropTypes.shape({
      currentActions: PropTypes.arrayOf(PropTypes.object),
      potentialActions: PropTypes.arrayOf(PropTypes.object),
      previousActions: PropTypes.arrayOf(PropTypes.object),
    }),
    assignableRoles: PropTypes.shape({
      externalRoles: PropTypes.arrayOf(PropTypes.object),
      internalRoles: PropTypes.arrayOf(PropTypes.object),
    }),
    canEdit: PropTypes.bool,
    clientName: PropTypes.string,
    downloads: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })).isRequired,
    hasActions: PropTypes.bool,
    hasCurrentActions: PropTypes.bool,
    jobId: PropTypes.string,
    member: PropTypes.object,
    members: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    packageId: PropTypes.string,
    packageName: PropTypes.string,
    reports: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      engagementCode: PropTypes.string,
      jobId: PropTypes.string,
      packageName: PropTypes.string,
    }),
  }).isRequired,
  modals: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploaders: PropTypes.object.isRequired,
  validations: PropTypes.object.isRequired,
}

const Job = (props) => {
  const {
    app: { user }, forms, job, modals, uploaders, routes, validations, dispatch,
    match: { params: { jobId } },
  } = props

  const [showEditDataWarning, setShowEditDataWarning] = useState(false)
  const { isExternal, _isFetched: _isUserFetched } = user

  useEffect(() => {
    if (_isUserFetched) {
      dispatch(fetchJobDetails({ jobId }))
      dispatch(fetchReports(jobId))
      dispatch(fetchValidationsSummary(jobId))
      dispatch(fetchCurrentActions({ jobId }))
    }
  }, [_isUserFetched, dispatch, jobId])

  useEffect(() => {
    if (job && !job._isFetchingJob && !job.canEdit) {
      dispatch(push('/home'))
    }
  }, [dispatch, job])

  const handleSubmitExternalUser = () => {
    dispatch(updateExternalUsers(job.jobId))
  }

  const handleRemoveMember = (userId, userDisplayName, callback) => {
    dispatch(removeMember(job.jobId, userId, userDisplayName, callback))
  }

  const openJobPopup = () => {
    dispatch(modalShow(constants.MODAL_MANAGE_JOB))
  }

  const backLocation = isExternal ? `/insights/${job.packageId}` : '/jobs/engagements'

  const canAddExternalUser = canInviteClientUsers(user) && (isManagerOrPartnerOnJob(user, job.members) || user.isAdmin)
  const accessActionHub = canAccessActionHub(user)
  const showClientAccess = user.isAdmin && user.isAuOffice

  const goToEditData = () => {
    dispatch(push(`/create/${jobId}/inputdata`))
  }

  const handleEditData = () => {
    setShowEditDataWarning(true)
  }

  const filteredRoutes = routes.filter(r => isExternal || r.path !== '/job/:jobId/team')

  const summaryTabs = [
    {
      title: 'Report',
      count: job.reports?.length ?? 0,
      suffix: 'details',
    },
    {
      title: 'Data issue',
      count: validations?.validationCount ?? 0,
      suffix: 'errors',
    },
    {
      title: 'Action set',
      count: job.actions?.currentActions?.length ?? 0,
      suffix: 'actions',
    },
  ]

  return (
    <div>
      {!job._isFetchingJob && (
        <>
          <JobTitleBanner
            backLocation={backLocation}
            isExternal={isExternal}
            job={job}
          />
          <JobSummaryBanner
            isExternal={isExternal}
            job={job}
            tabs={summaryTabs}
          />
        </>
      )}
      <div>
        <Container>
          {(job._isFetchingJob || job.jobId !== jobId || job.name.length === 0) ? <Loading pageLoading /> : (
            <>
              <Switch>
                {filteredRoutes.map(route => (
                  <RouteWithSubRoutes
                    key={route.path}
                    {...route}
                    canAccessActionHub={accessActionHub}
                    canAddExternalUser={canAddExternalUser}
                    dispatch={dispatch}
                    job={job}
                    members={job.members}
                    modals={modals}
                    onEditData={handleEditData}
                    onRemoveMember={handleRemoveMember}
                    openExternalUserModal={() => dispatch(modalShow(constants.MODAL_ADD_EXTERNAL_USER))}
                    openJobPopup={openJobPopup}
                    showActionHub={accessActionHub && job.hasActions}
                    showClientAccess={showClientAccess}
                    uploaders={uploaders}
                    user={user}
                    validations={validations}
                  />
                ))}
              </Switch>
            </>
          )}

          {job && !job._isFetching && job.name && job.members && (
            <Fragment>
              <AddExternalUserModal
                dispatch={dispatch}
                forms={forms}
                job={job}
                modals={modals}
                onSubmit={handleSubmitExternalUser}
                roles={job.assignableRoles?.externalRoles}
              />
              <ManageJobModal
                dispatch={dispatch}
                forms={forms}
                job={job}
                key={job.jobId}
                modals={modals}
                onSubmit={(formId, id, members) => dispatch(updateMembers(formId, {
                  jobId: id,
                  members,
                }))}
                userName={user.username}
              />
            </Fragment>
          )}
          <EditDataWarningDialog
            hasCurrentActions={job.hasCurrentActions}
            isOpen={showEditDataWarning}
            onAction={goToEditData}
            onDismiss={() => setShowEditDataWarning(false)}
          />
        </Container>
      </div>
    </div>
  )
}

Job.propTypes = propTypes

const mapStateToProps = state => ({
  job: state.job,
  uploaders: state.uploaders,
  app: state.app,
  modals: state.modals,
  forms: state.forms,
  validations: state.validations,
})

export default hot(withRouter(connect(mapStateToProps)(Job)))
