import React from 'react'
import Avatar from 'components/Avatar'
import { getUserDisplayString } from 'utils/users'
import PropTypes from 'prop-types'
import IconAvatarUnknown from 'icons/IconAvatarUnknown'
import styles from './User.scss'

function User({ showEmail, user }) {
  if (!user) {
    return <div />
  }

  return (
    <div className={styles.base}>
      {user.userDetails.email ? (
        <Avatar className={styles.avatar} size="medium" userDetails={user.userDetails} />
      ) : (
        <IconAvatarUnknown className={styles.avatar} height={36} width={36} />
      )}
      <div className={styles.details}>
        <div className={showEmail ? styles.bold : ''}>{getUserDisplayString(user)}</div>
        {showEmail && <div className={styles.email}>{user.userDetails.email}</div>}
      </div>
    </div>
  )
}

User.propTypes = {
  showEmail: PropTypes.bool,
  user: PropTypes.shape({
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      surname: PropTypes.string,
    }),
  }).isRequired,
}

User.defaultProps = {
  showEmail: false,
}

export default User
