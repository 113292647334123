import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionHubFetchingTasks,
  actionHubFetchedTasks,
  actionHubFetchedAction,
  actionHubFetchingAction,
  submittedTaskAssignee,
  submittedTaskPriority,
  selectedTabUpdate,
  actionHubFetchedTags,
  actionHubFetchingTags,
  tagCreated,
  actionHubUpdatedTagTaskAssociation,
  fetchedUpdatedTasksTokens,
  submittedTaskActionState,
  actionHubAddingTask,
} from 'actionHub/redux/actions'
import {
  getTasks, getAction, putTasksAssignee, putTasksPriority, getTags, postTag,
  putTag, removeTagAssociation, getGlobalFilters, putTasksActionStates, postNewTask,
} from 'actionHub/utils/actionHubApi'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import useApp from 'hooks/useApp'

export default function useAction(actionSetId) {
  const dispatch = useDispatch()
  const { showNotification } = useApp()
  const [filters, setFilters] = useState({})

  const tags = useSelector(state => state.actionHub.tags)
  const actionSet = useSelector(state => state.actionHub.actionSet)
  const stateActionSetId = useSelector(state => state.actionHub.actionSetId)
  const taskData = useSelector(state => state.actionHub.tasks)
  const {
    dataIds: actionTaskIds, displayColumns, idColumns, summaryStatement, totalActions, globalFilters,
  } = useSelector(state => state.actionHub.taskLevel)
  const statuses = useSelector(state => state.actionHub.statuses)
  const priorities = useSelector(state => state.actionHub.priorities)
  const filterStatuses = useSelector(state => state.actionHub.filterStatuses)
  const isCurrent = useSelector(state => state.actionHub.isCurrent)
  const displayName = useSelector(state => state.actionHub.displayName)
  const { allowTags } = useSelector(state => state.actionHub)
  const selectedTab = useSelector(state => state.actionHub.selectedTab)
  const actionStates = useSelector(state => state.actionHub.actionStates)
  const detailColumns = useSelector(state => state.actionHub.taskLevel.displayDetailColumns)

  const {
    _isFetched: isActionFetched,
    actionSetId: tasksActionSetId, jobId,
  } = actionSet

  const isFetched = isActionFetched

  const currentFilterQuery = queryString.stringify(filters, { skipNull: true })
  const filterStatus = filterStatuses[currentFilterQuery]

  useEffect(() => {
    if (actionSetId !== stateActionSetId) {
      dispatch(actionHubFetchingAction(actionSetId))

      getAction(actionSetId)
        .then(({ data }) => {
          const taskLevelMeta = data.dataLevels.find(x => x.level === 0)
          const dataLevelMeta = data.dataLevels.find(x => x.level === 1)

          dispatch(actionHubFetchedAction(actionSetId, {
            statuses: taskLevelMeta.progress.map(x => ({
              ...x,
              filterFunction: y => y.statusId === x.id,
            })),
            taskLevelMeta,
            dataLevelMeta,
            actionSet: data.actionSet, // todo keep this in same place
            actionStates: data.taskActions,
            priorities: data.priorities,
            commentStatuses: data.commentStatuses,
            displayName: data.displayName,
            isCurrent: data.isCurrent,
            allowTags: taskLevelMeta.allowTags,
          }))
        })
        .catch((e) => {
          showNotification('Could not load action, please refresh the page and contact support if issues persist.', e, 'error')
        })

      dispatch(actionHubFetchingTags(actionSetId))

      getTags(actionSetId)
        .then(({ data }) => {
          dispatch(actionHubFetchedTags(actionSetId, data))
        })
        .catch((e) => {
          showNotification('Could not load tags, please refresh the page and contact support if issues persist.', e, 'error')
        })
    }
  }, [dispatch, actionSetId, stateActionSetId, showNotification])

  useEffect(() => {
    if (actionSetId !== tasksActionSetId && !taskData._isFetching && !filterStatus) {
      dispatch(actionHubFetchingTasks(actionSetId))

      getTasks(actionSetId, currentFilterQuery, 0)
        .then(({ data }) => {
          const mappedTasks = data.items.map(item => ({
            ...item,
          }))

          dispatch(actionHubFetchedTasks(
            actionSetId,
            mappedTasks,
            currentFilterQuery,
            { hasNext: data.hasNext, page: data.page, totalItems: data.totalItems },
          ))
        })
        .catch((e) => {
          showNotification('Could not load tasks, please refresh the page and contact support if issues persist.', e, 'error')
        })
    }
  }, [dispatch, actionSetId, tasksActionSetId, currentFilterQuery, filterStatus, showNotification, taskData._isFetching])

  const fetchNextPage = () => {
    if (filterStatus.hasNext && !taskData._isFetching) {
      dispatch(actionHubFetchingTasks(actionSetId))
      getTasks(actionSetId, currentFilterQuery, filterStatus.page + 1)
        .then(({ data }) => {
          dispatch(actionHubFetchedTasks(
            actionSetId,
            data.items,
            currentFilterQuery,
            { hasNext: data.hasNext, page: data.page, totalItems: data.totalItems },
          ))
        })
        .catch((e) => {
          showNotification('Could not load tasks, please refresh the page and contact support if issues persist.', e, 'error')
        })
    }
  }

  const setFilter = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    })
  }

  const updateGlobalFilters = () => {
    getGlobalFilters(actionSetId)
      .then(({ data }) => {
        dispatch(fetchedUpdatedTasksTokens(data))
      })
      .catch((e) => {
        showNotification('Could not fetch the tasks tokens, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const deleteTagAssociation = (taskIds) => {
    removeTagAssociation(actionSetId, taskIds)
      .then(() => {
        getTags(actionSetId)
          .then((tagsData) => {
            dispatch(actionHubFetchedTags(actionSetId, tagsData.data))
            dispatch(actionHubUpdatedTagTaskAssociation(taskIds, null))
            showNotification('Tag association removed for the selected tasks.')
          })
        updateGlobalFilters()
      })
      .catch((e) => {
        showNotification('Could not delete tag(s) association, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const createTag = (taskIds, name, description) => {
    postTag(actionSetId, taskIds, name, description)
      .then(({ data }) => {
        dispatch(tagCreated(data))
        dispatch(actionHubUpdatedTagTaskAssociation(taskIds, data.id))
        updateGlobalFilters()
        showNotification('Tag created and associated with the selected tasks.')
      })
      .catch((e) => {
        showNotification('Could not create tag, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const onTabChange = (globalFilter) => {
    dispatch(push(`/action/${actionSetId}?globalFilter=${globalFilter}`))
    setFilter('globalFilter', globalFilter)
    dispatch(selectedTabUpdate(globalFilter))
  }

  const goToAction = () => {
    dispatch(push(`/action/${actionSetId}`))
  }

  const goToOtherAction = (actionId) => {
    dispatch(push(`/action/${actionId}`))
  }

  const goToTask = (taskId) => {
    dispatch(push(`/action/${actionSetId}/task/${taskId}`))
  }

  const goToTag = (tagId) => {
    dispatch(push(`/action/${actionSetId}/tag/${tagId}`))
  }

  const getAssignee = (assigneeType) => {
    switch (assigneeType) {
      case 'clientTeamId':
        return 'client'
      case 'reviewerId':
        return 'reviewer'
      default:
        return 'assignee'
    }
  }

  const setTasksAssigned = (taskIds, userId, assigneeType) => {
    putTasksAssignee(actionSetId, taskIds, userId, assigneeType)
      .then(() => {
        dispatch(submittedTaskAssignee(actionSetId, taskIds, userId, assigneeType))
        updateGlobalFilters()
        showNotification(`Updated ${getAssignee(assigneeType)} for selected task${taskIds.length > 1 ? 's' : ''}`)
      })
      .catch((e) => {
        showNotification('Could not save assignee, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const setTasksActionState = (taskIds, taskActionId) => {
    putTasksActionStates(actionSetId, taskIds, taskActionId)
      .then(() => {
        dispatch(submittedTaskActionState(actionSetId, taskIds, taskActionId))
        updateGlobalFilters()
        showNotification('Action state updated')
      })
      .catch((e) => {
        showNotification('Could not save Action state, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const setUpdatedTag = (dataIds, name, description, tagId, narrative) => {
    putTag(actionSetId, dataIds, name, description, tagId, narrative)
      .then(() => {
        getTags(actionSetId)
          .then((tagsData) => {
            dispatch(actionHubFetchedTags(actionSetId, tagsData.data))
            dispatch(actionHubUpdatedTagTaskAssociation(dataIds, tagId))
            showNotification('Tag associated with the selected tasks.')
          })
        updateGlobalFilters()
      })
  }

  const setTasksPriority = (taskIds, priorityId) => {
    putTasksPriority(actionSetId, taskIds, priorityId)
      .then(() => {
        dispatch(submittedTaskPriority(actionSetId, taskIds, priorityId))
        showNotification('Priorities updated')
      })
      .catch((e) => {
        showNotification('Could not save priority, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  const addNewTask = (taskDetails) => {
    dispatch(actionHubAddingTask(true))
    postNewTask(actionSetId, jobId, taskDetails)
      .then(() => {
        showNotification('Task added')
        getTasks(actionSetId, currentFilterQuery, 0)
          .then(({ data }) => {
            dispatch(actionHubAddingTask(false))
            dispatch(actionHubFetchedTasks(
              actionSetId,
              data.items,
              currentFilterQuery,
              {
                hasNext: data.hasNext,
                page: data.page,
                totalItems: data.totalItems,
              },
            ))
          })
      })
      .catch((e) => {
        showNotification('Could not load detail columns, please refresh the page and contact support if issues persist.', e, 'error')
      })
  }

  return {
    actionStates,
    allowTags,
    tags,
    actionSet,
    isCurrent,
    displayName,
    tasks: actionTaskIds.map(id => taskData[id]),
    columns: displayColumns.map(x => ({ ...x, idColumn: idColumns.includes(x.name) })),
    globalFilters,
    selectedTab,
    statuses,
    priorities,
    jobId,
    deleteTagAssociation,
    setUpdatedTag,
    createTag,
    goToAction,
    goToOtherAction,
    goToTask,
    goToTag,
    setTasksAssigned,
    setTasksActionState,
    setTasksPriority,
    setFilter,
    onTabChange,
    updateGlobalFilters,
    isFetched,
    isFetching: taskData._isFetching,
    filterStatus: filterStatus || {},
    mainAggregation: {
      type: 'primary',
      description: summaryStatement,
      totalActions,
    },
    fetchNextPage,
    addNewTask,
    detailColumns,
  }
}

export const useActionPropTypes = PropTypes.shape({
  actionSet: PropTypes.object,
  allowTags: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object),
  displayName: PropTypes.string,
  isCurrent: PropTypes.bool,
  tags: PropTypes.object,
  tasks: PropTypes.arrayOf(PropTypes.object),
})
