export const initialState = {
  selectedTab: null,
  actionSetId: null,
  actionSet: {
    _isFetching: false,
    _isFetched: false,
  },
  tags: {
    tagsList: [],
    _isFetching: false,
    _isFetched: false,
  },
  taskLevel: {
    displayColumns: [],
    displayDetailColumns: [],
    dataIds: [],
  },
  dataLevel: {
    displayColumns: [],
  },
  priorities: [],
  statuses: [],
  actionStates: [],
  commentStatuses: [],
  tasks: {
    _isFetching: false,
    _isFetched: false,
  },
  selectedTasks: [],
  _isSelectedTasksUpdating: false,
  data: {
    _isFetching: false,
    _isFetched: false,
  },
  filterStatuses: {},
  isCurrent: true,
  displayName: 'Action',
  taskListScrollLocation: 0,
  taskListSearchQuery: '',
  _isActionImported: false,
  _isImportingAction: false,
  _isUpdatingDetails: false,
  _isAddingTask: false,
  commentAuthors: {},
}
