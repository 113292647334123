import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icons } from '@deloitte/gel-library'
import { copyTextToClipboard } from 'utils/clipboard'
import styles from './PasswordHolder.scss'

class PasswordHolder extends Component {
  static propTypes = {
    password: PropTypes.string.isRequired,
  }

  state = {
    copiedMessageVisible: false,
  }

  handleCopyPassword = () => {
    const { password } = this.props
    copyTextToClipboard(password, this.handleCopyResult)
  }

  handleCopyResult = (successful) => {
    if (successful) {
      this.setState(
        () => ({ copiedMessageVisible: true }),
        () => {
          setTimeout(
            () => this.setState(() => ({ copiedMessageVisible: false })),
            3000,
          )
        },
      )
    }
  }

  render() {
    const { password } = this.props
    const { copiedMessageVisible } = this.state

    return (
      <div className={styles.base}>
        <div className={styles.password}>{password}</div>
        <Button mode="flat" onClick={this.handleCopyPassword}>
          {copiedMessageVisible ? (
            <span style={{ fontWeight: '100px' }}>
              <Icons.IconSuccess className={styles.successIcon} />
              COPIED
            </span>
          ) : (
            'COPY PASSWORD'
          )}
        </Button>
      </div>
    )
  }
}

export default PasswordHolder
