import { getRequest, parseError } from 'utils/api'
import { getJobActions, getJobDownloads, getJobDownloadStatus } from 'utils/api/job'
import { decorateJob } from 'utils/business/jobs'
import { notify, showErrorPage } from './app'
import { updateJobDownload } from './job'

export const FETCHING_CLIENT_JOB_SUMMARY = 'FETCHING_CLIENT_JOB_SUMMARY'
export const FETCHED_CLIENT_JOB_SUMMARY = 'FETCHED_CLIENT_JOB_SUMMARY'
export const FETCHING_CLIENT_JOBS = 'FETCHING_CLIENT_JOBS'
export const FETCHED_CLIENT_JOBS = 'FETCHED_CLIENT_JOBS'
export const FETCHING_CLIENT_JOB = 'FETCHING_CLIENT_JOB'
export const FETCHED_CLIENT_JOB = 'FETCHED_CLIENT_JOB'
export const UPDATED_CLIENT_JOB = 'UPDATED_CLIENT_JOB'
export const FETCHED_CLIENT_CUSTOM_REPORT_SUMMARY = 'FETCHED_CLIENT_CUSTOM_REPORT_SUMMARY'
export const FETCHING_CLIENT_CUSTOM_REPORTS = 'FETCHING_CLIENT_CUSTOM_REPORTS'
export const FETCHED_CLIENT_CUSTOM_REPORTS = 'FETCHED_CLIENT_CUSTOM_REPORTS'
export const FETCHING_CLIENT_CUSTOM_REPORT = 'FETCHING_CLIENT_CUSTOM_REPORT'
export const FETCHED_CLIENT_CUSTOM_REPORT = 'FETCHED_CLIENT_CUSTOM_REPORT'
export const FETCHING_CLIENT_ACTIONS = 'FETCHING_CLIENT_ACTIONS'
export const FETCHED_CLIENT_ACTIONS = 'FETCHED_CLIENT_ACTIONS'
export const FETCHING_CLIENT_DOWNLOADS = 'FETCHING_CLIENT_DOWNLOADS'
export const FETCHED_CLIENT_DOWNLOADS = 'FETCHED_CLIENT_DOWNLOADS'
export const FETCHED_CLIENT_DOWNLOAD_UPDATE = 'FETCHED_CLIENT_DOWNLOAD_UPDATE'

const fetchingClientJobSummary = () => ({
  type: FETCHING_CLIENT_JOB_SUMMARY,
})

const fetchedClientJobSummary = jobs => ({
  type: FETCHED_CLIENT_JOB_SUMMARY,
  jobs,
})

const fetchedCustomReportSummary = count => ({
  type: FETCHED_CLIENT_CUSTOM_REPORT_SUMMARY,
  count,
})

export const fetchClientJobSummary = () => {
  return (dispatch) => {
    dispatch(fetchingClientJobSummary())

    getRequest('/client/packages/jobs/summary')
      .then(({ data }) => {
        dispatch(fetchedClientJobSummary(data))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })

    getRequest('/client/reports/hosted/summary')
      .then(({ data }) => {
        dispatch(fetchedCustomReportSummary(data.reportCount))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

const fetchingClientJobs = packageId => ({
  type: FETCHING_CLIENT_JOBS,
  packageId,
})

const fetchedClientJobs = (packageId, jobs) => ({
  type: FETCHED_CLIENT_JOBS,
  packageId,
  jobs,
})

export const fetchClientJobs = (packageId) => {
  return (dispatch) => {
    dispatch(fetchingClientJobs(packageId))

    getRequest(`/client/packages/${packageId}/jobs`)
      .then(({ data }) => {
        const decoratedJobs = data.map(decorateJob)
        dispatch(fetchedClientJobs(packageId, decoratedJobs))
      })
  }
}

const fetchingClientJob = jobId => ({
  type: FETCHING_CLIENT_JOB,
  jobId,
})

const fetchedClientJob = (jobId, job) => ({
  type: FETCHED_CLIENT_JOB,
  jobId,
  job,
})

export const fetchClientJob = (jobId) => {
  return (dispatch) => {
    dispatch(fetchingClientJob(jobId))

    getRequest(`/client/job/${jobId}`)
      .then(({ data }) => {
        const decoratedJob = decorateJob(data)
        dispatch(fetchedClientJob(jobId, decoratedJob))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

const updatedClientJob = (jobId, job) => ({
  type: UPDATED_CLIENT_JOB,
  jobId,
  job,
})

export const fetchClientReports = (jobId) => {
  return (dispatch) => {
    dispatch(fetchingClientJob(jobId))

    getRequest(`/client/job/${jobId}/reports`)
      .then(({ data: reports }) => {
        dispatch(updatedClientJob(jobId, { reports }))
      })
      .catch(() => {
        dispatch(updatedClientJob(jobId, {}))
      })
  }
}

export const fetchingClientCustomReports = () => ({
  type: FETCHING_CLIENT_CUSTOM_REPORTS,
})

export const fetchedClientCustomReports = reportGroups => ({
  type: FETCHED_CLIENT_CUSTOM_REPORTS,
  groups: reportGroups,
})

export const fetchClientCustomReports = () => {
  return (dispatch) => {
    dispatch(fetchingClientCustomReports())

    getRequest('/client/reports/hosted')
      .then(({ data }) => {
        dispatch(fetchedClientCustomReports(data))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

export const fetchingClientCustomReport = () => ({
  type: FETCHING_CLIENT_CUSTOM_REPORT,
})

export const fetchedClientCustomReport = reportGroup => ({
  type: FETCHED_CLIENT_CUSTOM_REPORT,
  group: reportGroup,
})

export const fetchClientCustomReport = (groupId) => {
  return (dispatch) => {
    dispatch(fetchingClientCustomReports())

    getRequest(`/client/reports/hosted/${groupId}`)
      .then(({ data }) => {
        dispatch(fetchedClientCustomReport(data))
      })
      .catch((error) => {
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
  }
}

export const fetchingClientActions = () => ({
  type: FETCHING_CLIENT_ACTIONS,
})

export const fetchedClientActions = (jobId, actions) => ({
  type: FETCHED_CLIENT_ACTIONS,
  jobId,
  actions,
})

export const fetchClientActions = (jobId) => {
  return (dispatch) => {
    dispatch(fetchingClientActions())
    getJobActions(jobId)
      .then(({ data }) => {
        dispatch(fetchedClientActions(jobId, data))
      })
      .catch((e) => {
        dispatch(notify('Error fetching actions', 'error'))
        console.error(e)
      })
  }
}

const fetchingClientJobDownloads = jobId => ({
  type: FETCHING_CLIENT_DOWNLOADS,
  jobId,
})

const fetchedClientJobDownloads = (jobId, downloads) => ({
  type: FETCHED_CLIENT_DOWNLOADS,
  jobId,
  downloads,
})

export const fetchClientJobDownloads = (jobId) => {
  return (dispatch) => {
    dispatch(fetchingClientJobDownloads(jobId))
    getJobDownloads(jobId, true)
      .then(({ data }) => {
        dispatch(fetchedClientJobDownloads(jobId, data))
        const currentJobDownload = data.find(download => download.jobId === jobId)
        if (currentJobDownload) {
          dispatch(updateJobDownload(jobId, currentJobDownload.name, currentJobDownload))
        }
      })
      .catch(() => {
        dispatch(fetchedClientJobDownloads(jobId, []))
        dispatch(notify('No downloads available for this job.', 'error'))
      })
  }
}

export const clientUpdateDownload = (jobId, download) => ({
  type: FETCHED_CLIENT_DOWNLOAD_UPDATE,
  jobId,
  download,
})

export const fetchClientDownloadUpdate = (jobId, downloadName) => {
  return (dispatch) => {
    getJobDownloadStatus(jobId, downloadName, true)
      .then(({ data }) => {
        dispatch(clientUpdateDownload(jobId, data))
      })
  }
}

