import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@deloitte/gel-library'
import DialogSimple from 'components/DialogSimple'
import useData from 'actionHub/hooks/useData'
import Loading from 'components/Loading'
import DataItem from 'actionHub/components/DataItem'
import { userPropType } from 'utils/propTypes'
import LabelForm from '../LabelForm'
import styles from './EditDataDialog.scss'
import Comments from '../../components/Comments'

function EditDataDialog({
  actionSetId, taskId, isOpen, disabled, onDismiss, editingDataId, members,
}) {
  const {
    data, columns, setLabelValue, validateLabel, submitDataLabels,
  } = useData(actionSetId, taskId, editingDataId)
  const initialLabels = useRef()
  const isFetched = data && data._isFetched
  const labels = data ? data.labels : null

  useEffect(() => {
    if (isFetched && !initialLabels.current) {
      initialLabels.current = [...labels]
    }
  }, [isFetched, labels])

  const idColumns = isFetched && columns.filter(x => x.idColumn)
  const regularColumns = isFetched && columns.filter(x => !x.idColumn)

  return (
    <DialogSimple
      actionLabel={disabled ? null : 'Save'}
      className={styles.base}
      disabled={!isFetched || !data._isLabelsModified}
      dismissLabel="Close"
      isOpen={isOpen}
      onAction={() => {
        initialLabels.current = null
        submitDataLabels()
        onDismiss()
      }}
      onDismiss={() => {
        initialLabels.current.forEach((label) => {
          setLabelValue(label.id, label.value)
        })
        initialLabels.current = null
        onDismiss()
      }}
    >
      {isFetched
        ? (
          <div className={styles.content}>
            <Heading level={5}>
              {data.data && idColumns.map(c => (
                <span key={c.name}>
                  {`${c.displayName} ${data.data[c.name]}`}
                </span>
              ))}
            </Heading>
            <div className={styles.dataProperties}>
              {data.data && regularColumns.map(c => (
                <div key={c.name}>
                  <p className={styles.label}>{c.displayName}</p>
                  <p>
                    <DataItem column={c} value={data.data[c.name]} />
                  </p>
                </div>
              ))}
            </div>
            {data.labels.length > 0 && (
              <>
                <Heading level={7}>Review findings</Heading>
                <LabelForm
                  actionSetId={actionSetId}
                  className={styles.labelForm}
                  disabled={disabled}
                  isModified={data._isLabelsModified}
                  labels={data.labels}
                  setLabelValue={setLabelValue}
                  taskId={taskId}
                  validateLabel={validateLabel}
                />
              </>
            )}
            {!(disabled && data.comments.length === 0) && <Heading level={7}>Comments</Heading>}
            <Comments
              actionSetId={actionSetId}
              dataId={editingDataId}
              disabled={disabled}
              members={members}
              taskId={taskId}
            />
          </div>
        ) : (
          <Loading pageLoading />
        )}

    </DialogSimple>
  )
}

EditDataDialog.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  editingDataId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(userPropType),
  onDismiss: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
}

EditDataDialog.defaultProps = {
  editingDataId: null,
  members: [],
}

export default EditDataDialog

