import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'components/layout/Grid/'
import classnames from 'classnames'
import { Icons } from '@deloitte/gel-library'
import { filterBannersByAudience } from 'utils/users'
import Wordmark from 'components/Wordmark'
import InfoBanner from 'components/InfoBanner'
import UserAvatarPopover from 'components/UserAvatarPopover'
import { connect } from 'react-redux'
import { hot } from 'react-hot-loader/root'
import { resetCloningState } from 'actions/job'
import NavLinks from './NavLinks'
import styles from './Header.scss'

class Header extends Component {
  static propTypes = {
    activeBanners: PropTypes.object.isRequired,
    handleLoseData: PropTypes.func.isRequired,
    jobs: PropTypes.object.isRequired,
    noJob: PropTypes.bool.isRequired,
    onLogoClick: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    simple: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    wordmark: PropTypes.string.isRequired,
  }

  state = {
    didScroll: false,
    lastScrollTop: 0,
    delta: 5,
    showHeader: true,
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
        didScroll: true,
      })
    })

    this.scrollInterval = setInterval(() => {
      const { didScroll } = this.state
      if (didScroll) {
        this.hasScrolled()
        this.setState({
          didScroll: false,
        })
      }
    }, 250)
  }

  componentWillUnmount() {
    clearInterval(this.scrollInterval)
  }

  hasScrolled = () => {
    const { delta, lastScrollTop } = this.state
    const st = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0)

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) { return }

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    this.setState({
      showHeader: !(st > lastScrollTop && st > 72),
      lastScrollTop: st,
    })
  }

  handleSupport = () => {
    const { push } = this.props
    push('/support')
  }

  render() {
    const {
      activeBanners,
      simple,
      noJob,
      jobs,
      user,
      handleLoseData,
      wordmark,
      push,
      onLogoClick,
    } = this.props
    const { showHeader } = this.state
    const banners = activeBanners ? filterBannersByAudience(Object.values(activeBanners), user) : []

    const bannerClassname = banners.length > 0
      ? `banners-${banners.length}`
      : null

    const handleLogoClick = () => {
      onLogoClick()
      push('/home')
    }

    return (
      <div className={classnames('header-sticky', showHeader ? 'nav-down' : 'nav-up', bannerClassname, 'mui-fixed')}>
        <div className={classnames(styles.base, bannerClassname)}>
          <Fragment>
            {banners.map(b => (
              <InfoBanner
                key={b.name}
                link={b.linkUrl}
                linkText={b.linkText}
                message={b.message}
                mode={b.mode.toLowerCase()}
              />
            ))}
          </Fragment>
          <Container className={styles.container}>
            <span className={styles.logo}>
              <a
                className={styles.link}
                onClick={() => handleLogoClick()}
              >
                <Wordmark wordmark={wordmark} />
              </a>
              {' '}
            </span>
            {user._isFetched
              && (!user.isExternal || user.hasAcceptedEula) && (
              <section className={styles.rightlogo}>
                {!noJob && (
                <NavLinks
                  handleLoseData={handleLoseData}
                  jobs={jobs}
                  simple={simple}
                  user={user}
                />
                )}
                <div className={styles.pipe} />

                <UserAvatarPopover user={user} />

                <a className={styles.supportLink} onClick={this.handleSupport}>
                  <Icons.IconHelp
                    className={styles.helpIcon}
                    height="24"
                    width="24"
                  />
                  Support
                </a>
              </section>
            )}
          </Container>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoClick: () => {
      dispatch(resetCloningState())
    },
  }
}

const mapStateToProps = ({
  admin: { bannerAccess: { activeBanners } },
}) => {
  return {
    activeBanners,
  }
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(Header))
