import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'
import useTask from 'actionHub/hooks/useTask'
import Loading from 'components/Loading'
import CaseDescription from 'components/CaseDescription'
import { jobPermissions } from 'utils/permissions'
import DetailedViewHeader from 'components/DetailedViewHeader'
import DataList from 'actionHub/views/DataList/DataList'
import ReviewSection from 'actionHub/components/ReviewSection'
import DataPageWrapper from 'actionHub/views/DataPageWrapper/DataPageWrapper'
import EditDataDialog from 'actionHub/views/EditDataDialog/EditDataDialog'
import { useActionPropTypes } from 'actionHub/hooks/useAction'
import { useJobPropTypes } from 'hooks/useJob'
import EditDetailsForm from 'actionHub/views/EditDetailsForm'
import { useDispatch, useSelector } from 'react-redux'
import { modalCreate, modalShow } from 'actions/modals'
import constants from 'constants'
import taskStyles from './Task.scss'

function Task({ useJob, useAction, match: { params: { actionSetId, taskId } } }) {
  const dispatch = useDispatch()
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [editingDataId, setEditingDataId] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const modals = useSelector(state => state.modals)
  const MODAL_ID = constants.MODAL_EDIT_TASK_DETAILS

  const {
    data, columns, taskData, meta, taskTitle, setLabelValue, isFetched, fetchNextPage,
    submitTaskLabels, validateLabel, setTasksActionState, setTaskAssignee, setTaskPriority,
    allowComments, detailColumns, dataCount, updateTaskDetails,
  } = useTask(actionSetId, taskId)

  const {
    isCurrent, priorities, columns: taskColumns, setUpdatedTag, deleteTagAssociation, allowTags, actionStates,
  } = useAction

  useEffect(() => {
    if (modals && !modals.modalNames.includes(MODAL_ID)) {
      dispatch(modalCreate(MODAL_ID))
    }
  }, [modals, MODAL_ID, dispatch])

  const onEditDetailsClick = useCallback(() => {
    dispatch(modalShow(MODAL_ID))
  }, [MODAL_ID, dispatch])

  const handleDataClick = useCallback((dataItem) => {
    setEditingDataId(dataItem._id)
    setIsDialogVisible(true)
  }, [])

  const handleDialogDismiss = useCallback(() => {
    setEditingDataId(null)
    setIsDialogVisible(false)
  }, [])

  if (!data || !data._isFetched || !isFetched) {
    return <Loading pageLoading />
  }

  const {
    deloitteTeam, externalTeam, userHasJobPermission, job: { hasExpired }, isAssignedActionsEditor,
  } = useJob

  const updatedUser = [...deloitteTeam, ...externalTeam]
    .find(m => m.userDetails.email === data.updatedBy)

  const canEditActions = userHasJobPermission(jobPermissions.actionHubEdit)
  const disabled = !isCurrent || !canEditActions || hasExpired

  return (
    <div className={taskStyles.base}>
      <div className={taskStyles.caseHeader}>
        <DetailedViewHeader
          actionSetId={actionSetId}
          actionStates={actionStates}
          allowTags={allowTags}
          clients={externalTeam}
          deleteTagAssociation={deleteTagAssociation}
          disabled={disabled}
          headerType="caseView"
          hideDropdowns={isAssignedActionsEditor}
          members={deloitteTeam}
          priorities={priorities}
          setTaskAssignee={setTaskAssignee}
          setTaskPriority={setTaskPriority}
          setTaskTag={setUpdatedTag}
          setTasksActionState={setTasksActionState}
          task={data}
          taskTitle={taskTitle}
          timestamp={data.updatedDate === null ? '' : data.updatedDate}
          updatedUser={updatedUser}
        />
      </div>

      <DataPageWrapper
        expanded={expanded}
        hasChildData={data._hasChildData}
      >
        <div className={taskStyles.leftContainer}>
          <CaseDescription
            isFullHeight
            className={taskStyles.leftContainer}
            columns={detailColumns || taskColumns}
            onEditDetailsClick={onEditDetailsClick}
            task={data}
          />
        </div>
        <div className={taskStyles.rightContainer}>
          <ReviewSection
            actionSetId={actionSetId}
            allowComments={allowComments}
            comments={data.comments}
            disabled={disabled}
            object={data}
            setLabelValue={setLabelValue}
            submitLabels={submitTaskLabels}
            taskId={taskId}
            validateLabel={validateLabel}
          />
        </div>
      </DataPageWrapper>

      {detailColumns && modals.modalNames.includes(MODAL_ID) && (
        <EditDetailsForm
          columns={detailColumns}
          isNewForm={false}
          modalId={MODAL_ID}
          onSaveClick={updateTaskDetails}
          task={data}
        />
      )}

      {isFetched && data._hasChildData && (
        <>
          <DataList
            columns={columns}
            data={taskData}
            dataCount={dataCount}
            expanded={expanded}
            meta={meta}
            onClick={handleDataClick}
            onLoadMore={fetchNextPage}
            setExpanded={setExpanded}
          />
          <EditDataDialog
            actionSetId={actionSetId}
            disabled={!isCurrent || !canEditActions}
            editingDataId={editingDataId}
            isOpen={isDialogVisible}
            members={deloitteTeam}
            onDismiss={handleDialogDismiss}
            taskId={taskId}
          />
        </>
      )}
    </div>

  )
}

Task.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      actionSetId: PropTypes.string,
      taskId: PropTypes.string,
    }),
  }).isRequired,
  useAction: useActionPropTypes.isRequired,
  useJob: useJobPropTypes.isRequired,
}

export default hot(Task)

