import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { IconArrowBackward } from 'icons'
import Container from 'components/layout/Grid/Container'
import ManageTeam from 'components/ManageTeam'
import JobInfoBanner from 'components/JobInfoBanner'
import classnames from 'classnames'
import styles from './JobTitleBanner.scss'

const JobTitleBanner = ({
  backLocation, history, isCloning, isExternal, job,
}) => {
  const handleBackBtnClick = () => {
    history.push(backLocation)
  }

  const reachedExecutionLimit = job.hasExecutionLimit && job.noOfRuns >= job.executionLimit
  const showExpiredJobBanner = reachedExecutionLimit || job.hasExpired

  return (
    <div
      className={classnames(styles.header, {
        [styles.headerExpired]: showExpiredJobBanner,
        [styles.headerCloning]: isCloning,
      })}
    >
      <Container className={styles.container}>
        <div className={styles.flex}>
          <div className={styles.jobDetails}>
            {!isCloning && (
              <a className={styles.backButton} onClick={handleBackBtnClick}>
                <IconArrowBackward className={styles.icon} size="16" />
              </a>
            )}
            {job.name && job.packageName && (
              <>
                {!isCloning ? (
                  <>
                    <span className={styles.jobName}>{job.name}</span>
                    <div className={styles.divider} />
                    <span className={styles.packageName}>{job.packageName}</span>
                  </>
                ) : (
                  <span className={styles.greyTitle}>{`Copying job '${job.name}'`}</span>
                )}
              </>
            )}
          </div>
        </div>

        {!isExternal && job.members && (
          <div className={styles.flex}>
            <ManageTeam onDark />
          </div>
        )}
      </Container>

      {isCloning && (
        <JobInfoBanner isCloning />
      )}

      {!isCloning && showExpiredJobBanner && job.hasExecuted && (
        <JobInfoBanner
          hasExpired={job.hasExpired}
          reachedExecutionLimit={reachedExecutionLimit}
        />
      )}
    </div>
  )
}

JobTitleBanner.propTypes = {
  backLocation: PropTypes.string,
  history: PropTypes.object.isRequired,
  isCloning: PropTypes.bool,
  isExternal: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    executionLimit: PropTypes.number,
    hasExecuted: PropTypes.bool,
    hasExecutionLimit: PropTypes.bool,
    hasExpired: PropTypes.bool,
    jobId: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    noOfRuns: PropTypes.number,
    packageId: PropTypes.string,
    packageName: PropTypes.string,
  }).isRequired,
}

JobTitleBanner.defaultProps = {
  backLocation: '/jobs/engagements',
  isCloning: false,
}

export default withRouter(JobTitleBanner)
