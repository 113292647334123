import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './CommentStatus.scss'

const CommentStatus = ({ commentStatus }) => (
  <span className={classNames(styles.base, styles[`comment-${commentStatus.id}`])}>
    {commentStatus.description}
  </span>
)

CommentStatus.propTypes = {
  commentStatus: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
  }),
}

CommentStatus.defaultProps = {
  commentStatus: {
    description: 'No status',
    id: 0,
  },
}

export default CommentStatus
