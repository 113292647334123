import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TabPanel.scss'

function TabPanel({
  className, children, value, index,
}) {
  return (
    <>
      {value === index && (
        <div className={classNames(styles.box, className)}>
          {children}
        </div>
      )}
    </>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.object,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

TabPanel.defaultProps = {
  children: null,
  className: null,
}

export default TabPanel
