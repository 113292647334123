import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useAction from 'actionHub/hooks/useAction'
import { Switch } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import Loading from 'components/Loading'
import MultiProgressBar from 'components/MultiProgressBar'
import useJob from 'hooks/useJob'
import { Helmet } from 'react-helmet'
import ReportHeader from 'components/ReportHeader'
import useApp from 'hooks/useApp'
import ListAltIcon from '@material-ui/icons/ListAlt'
import CancelIcon from '@material-ui/icons/Cancel'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LabelOffIcon from '@material-ui/icons/LabelOff'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import TabFilter from 'actionHub/views/TabFilter'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import classNames from 'classnames'
import TickmarkHolder from 'actionHub/views/TickmarkHolder'
import { Divider } from '@material-ui/core'
import ExpandContainer from 'components/ExpandContainer'
import styles from './ActionHome.scss'

function ActionHome({ routes, match: { params: { actionSetId } } }) {
  const STATUS_COMPLETED = 3

  const useActionResults = useAction(actionSetId)
  const {
    actionSet, onTabChange, jobId, tasks, isCurrent,
    goToOtherAction, goToTag,
    isFetched, globalFilters, allowTags, tags, selectedTab,
    mainAggregation, statuses,
  } = useActionResults
  const [expanded, setExpanded] = useState(true)

  const useJobResults = useJob(jobId)
  const {
    jobName, packageName, engagement, clientName, goToJob, getNavigationOptions,
    showExpiryInfoBanner, isAssignedActionsEditor, actions,
  } = useJobResults

  const {
    user,
  } = useApp()

  if (!isFetched || !jobName || !actions) {
    return <Loading pageLoading />
  }

  // Header config
  const {
    options, optionGroups, reportActionButton,
  } = getNavigationOptions(actionSet, isCurrent, goToOtherAction)

  const tabProperties = {
    all: { name: 'All', displayOrder: 1, icon: <ListAltIcon /> },
    incomplete: { name: 'Incomplete', displayOrder: 2, icon: <PlayCircleFilledIcon /> },
    assignedToMe: { name: 'Assigned to me', displayOrder: 3, icon: <AssignmentIndIcon /> },
    toBeReviewed: { name: 'To be reviewed', displayOrder: 4, icon: <ListAltIcon /> },
    excluded: { name: 'Excluded', displayOrder: 5, icon: <CancelIcon /> },
    noTag: { name: 'No tickmark', displayOrder: 5, icon: <LabelOffIcon /> },
    withClient: { name: 'With client', displayOrder: 6, icon: <SupervisedUserCircleIcon /> },
  }

  const tabs = [
    ...Object.keys(globalFilters).map(k => ({
      id: k,
      count: globalFilters[k],
      ...tabProperties[k],
    })),
  ]

  const completedActions = statuses.find(x => x.id === STATUS_COMPLETED).count
  const { totalActions } = mainAggregation
  const completionProgress = (totalActions !== 0) ? (completedActions / totalActions) : 0

  const tagClicked = (tag) => {
    onTabChange(null)
    goToTag(tag.id)
  }

  return (
    <div className={styles.base}>
      <Helmet>
        <title>
          {`InsightBox | ${jobName} | Actions`}
        </title>
      </Helmet>
      <ReportHeader
        actionButton={reportActionButton}
        completionProgress={completionProgress}
        goBack={() => goToJob('details')}
        jobId={jobId}
        navigationOptionGroups={optionGroups}
        navigationOptions={isCurrent ? options : null}
        navigationValue={actionSet.id}
        type="action"
        user={user}
      />
      {!showExpiryInfoBanner && !isAssignedActionsEditor && (
        <MultiProgressBar
          denominator={totalActions}
          roundEdges={false}
          value={completedActions}
        />
      )}
      <div className={styles.flexContainer}>
        {!isAssignedActionsEditor && (
          <div className={classNames(styles.sideNav, styles.flex)}>
            <div className={styles.heading}>
              CASES
            </div>
            <div>
              <TabFilter
                className={styles.tabs}
                data={tasks}
                onTabChange={onTabChange}
                startingTabId={selectedTab}
                tabs={tabs}
              />
            </div>
            <Divider />
            <div className={styles.tagContainer}>
              {allowTags
              && (
                <>
                  <div className={styles.heading}>
                    TICKMARKS
                  </div>
                  <TickmarkHolder clicked={tag => tagClicked(tag)} tags={tags} />
                </>
              )}
            </div>
            <ExpandContainer
              className={styles.expandContainer}
              expanded={!expanded}
              heading={<div>Job details</div>}
              setExpanded={() => setExpanded(!expanded)}
            >
              <div className={styles.jobDetailHeadings}>Job name</div>
              <div className={styles.jobDetailsValues}>{jobName}</div>
              <div className={styles.jobDetailHeadings}>Client</div>
              <div className={styles.jobDetailsValues}>{clientName}</div>
              {!user.isExternal && (
              <div>
                <div className={styles.jobDetailHeadings}>Engagement</div>
                <div className={styles.jobDetailsValues}>
                  {engagement.code}
                  {' '}
                  {engagement.name}
                </div>
              </div>
              )}
              <div className={styles.jobDetailHeadings}>Solution name</div>
              <div>{packageName}</div>
            </ExpandContainer>
          </div>
        )}

        <div className={classNames(styles.mainScreen, styles.flex)}>
          <Switch>
            {routes.map(route => (
              <RouteWithSubRoutes
                key={route.path}
                {...route}
                useAction={useActionResults}
                useJob={useJobResults}
              />
            ))}
          </Switch>

        </div>
      </div>
    </div>
  )
}

ActionHome.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      actionSetId: PropTypes.string,
    }),
  }).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
  })).isRequired,
}

export default hot(ActionHome)

