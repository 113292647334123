import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { fetchClientPackage } from 'actions/packages'
import { fetchClientActions, fetchClientJob, fetchClientReports } from 'actions/client'
import Loading from 'components/Loading'
import JobTitleBanner from 'components/JobTitleBanner'
import JobSummaryBanner from 'components/JobSummaryBanner'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import Container from 'components/layout/Grid/Container'
import useJob from 'hooks/useJob'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  job: PropTypes.shape({
    _fetchedJob: PropTypes.bool,
    actions: PropTypes.object,
    hasActions: PropTypes.bool,
    hasExpired: PropTypes.bool,
    jobMembers: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    reports: PropTypes.arrayOf(PropTypes.object),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobId: PropTypes.string,
      packageId: PropTypes.string,
    }),
  }).isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  solution: PropTypes.object,
  user: PropTypes.object.isRequired,
}

const defaultProps = {
  solution: null,
  job: null,
}

const ClientJob = (props) => {
  const {
    solution, dispatch,
    user, job, routes,
    match: { params: { jobId, packageId } },
  } = props

  const [summaryTabs, setSummaryTabs] = useState([])
  const {
    canAccessJobActions,
    filteredActions,
    isAssignedActionsEditor,
  } = useJob(jobId, true)

  useEffect(() => {
    dispatch(fetchClientJob(jobId))
    if (isAssignedActionsEditor !== null && !isAssignedActionsEditor) {
      dispatch(fetchClientReports(jobId))
    }
    if (solution === null) {
      dispatch(fetchClientPackage(packageId))
    }
  }, [dispatch, jobId, packageId, solution, isAssignedActionsEditor])

  useEffect(() => {
    if (jobId && canAccessJobActions) {
      dispatch(fetchClientActions(jobId))
    }
  }, [dispatch, jobId, user, canAccessJobActions])

  useEffect(() => {
    if (job) {
      const tabs = [
        {
          title: 'Report',
          count: job.reports?.length ?? 0,
          suffix: 'reports',
        },
        {
          title: 'Action set',
          count: filteredActions?.length ?? 0,
          suffix: 'actions',
        },
        {
          title: 'Team member',
          count: job.jobMembers?.length ?? 0,
          suffix: 'team',
        },
      ]

      if (!canAccessJobActions) { tabs.splice(1, 1) } // exclude actions
      if (isAssignedActionsEditor) { tabs.splice(0, 1) } // exclude reports
      setSummaryTabs(tabs)
    }
  }, [job, filteredActions?.length, canAccessJobActions, isAssignedActionsEditor])

  return (
    <div>
      {job?._fetchedJob && (
        <>
          <JobTitleBanner
            backLocation={`/insights/${packageId}`}
            isExternal={user.isExternal}
            job={job}
          />
          <JobSummaryBanner
            isExternal={user.isExternal}
            job={job}
            tabs={summaryTabs}
          />
        </>
      )}
      <div style={{ paddingTop: '36px' }}>
        <Container bottomMargin noMargin>
          {job && job._fetchedJob && solution
            ? (
              <Switch>
                {routes.map(route => (
                  <RouteWithSubRoutes
                    canAccessActionHub={canAccessJobActions}
                    key={route.path}
                    user={user}
                    {...route}
                    dispatch={dispatch}
                    job={job}
                    members={job.jobMembers}
                    onEditData={() => {}}
                      // unify with non-client Actions view
                    solution={solution}
                    uploaders={{ validations: {} }}
                    validations={{ uploaders: {} }}
                  />
                ))}
              </Switch>
            )
            : (
              <Loading pageLoading />
            )
          }
        </Container>
      </div>

    </div>
  )
}

const mapStateToProps = (
  { app: { user }, client: { jobs }, packages: { packages } },
  { match: { params: { packageId, jobId } } },
) => {
  return {
    user,
    solution: packages[packageId],
    job: jobs[jobId],
  }
}

ClientJob.propTypes = propTypes
ClientJob.defaultProps = defaultProps

export default connect(mapStateToProps)(ClientJob)
