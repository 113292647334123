/* eslint-disable no-param-reassign */

import { push } from 'connected-react-router'
import { getRequest, parseError, putRequest } from 'utils/api'
import { showErrorPage, notify } from 'actions/app'
import { updateJob as update } from 'actions/job'
import { decorateJob, roles } from 'utils/business/jobs'
import { jobIsFinalised } from 'utils/business/workflow'
import { isExternalFromState } from 'utils/users'

export const JOBS_FETCHING = 'JOBS_FETCHING'
export const JOBS_FETCHED = 'JOBS_FETCHED'
export const JOBS_UPDATE_JOB = 'JOBS_UPDATE_JOB'
export const JOBS_SET_FILTERS = 'JOBS_SET_FILTERS'
export const JOBS_CLEAR_FILTERS = 'JOBS_CLEAR_FILTERS'
export const JOBS_CLEAR_JOBS = 'JOBS_CLEAR_JOBS'
export const JOBS_ADMIN_RESET_START = 'JOBS_ADMIN_RESET_START'
export const JOBS_ADMIN_RESET_END = 'JOBS_ADMIN_RESET_END'
export const JOBS_ADMIN_RETRY_START = 'JOBS_ADMIN_RETRY_START'
export const JOBS_ADMIN_RETRY_END = 'JOBS_ADMIN_RETRY_END'

export const fetching = () => ({
  type: JOBS_FETCHING,
})

export const fetched = (jobs, isAdmin = false) => ({
  type: JOBS_FETCHED,
  jobs,
  isAdmin,
})

export const updateJob = job => ({
  type: JOBS_UPDATE_JOB,
  job,
})

export const clearJobs = () => ({
  type: JOBS_CLEAR_JOBS,
})

export function fetch(isAdmin = false) {
  return (dispatch, getState) => {
    const { _isAdminJobsLoaded } = getState().jobs
    if (isAdmin !== _isAdminJobsLoaded) {
      dispatch(clearJobs())
    }

    getRequest(isAdmin ? '/admin/job?errors=true' : '/dashboard/bootstrap')
      .then(({ data }) => {
        if (data && data.length >= 0) {
          if (data.length === 0) {
            dispatch(fetched([]))
          } else {
            dispatch(fetched(data.map(decorateJob), isAdmin))
          }
        } else {
          console.warn('failed to cancel upload')
        }
      })
      .catch((error) => {
        dispatch(fetched([]))
        const err = parseError(error)
        dispatch(showErrorPage(err))
      })
    dispatch(fetching())
  }
}

export function resume({ jobId }) {
  return (dispatch, getState) => {
    const isExternal = isExternalFromState(getState())

    const jobs = isExternal
      ? getState().client.jobs
      : getState().jobs.jobs

    const job = jobs[jobId]
    const { packages } = getState().packages
    const currentPackage = packages[job.packageId]

    const userId = getState().app.user.id
    const member = isExternal
      ? job.members.find(m => m.id === userId)
      : job.member

    if (jobIsFinalised(job)) {
      dispatch(update(job))
      const isNotClientJobOrExternalEditor = !job.isClientJob || (member?.role?.name ?? '') !== roles.JobExternalEditor
      const isExternalWithCorrectPermissions = isExternal && isNotClientJobOrExternalEditor
      const jobSuffix = member?.role?.name === roles.ExternalAssignedActionsEditor ? 'actions' : 'reports'

      dispatch(push(isExternalWithCorrectPermissions
        ? `/insights/${job.packageId}/${jobId}/${jobSuffix}`
        : `/job/${jobId}/details`))
    } else {
      switch (job.step) {
        case 1:
        case 2:
          dispatch(push(`/create/${jobId}/selecttests`))
          break
        case 3:
          if (job.jobStatusDesc === 'DataPreparation') {
            dispatch(push(`/create/${jobId}/getdata`))
          }
          break
        case 4:
          if (job.isDataReady) {
            dispatch(push(`/create/${jobId}/inputdata`))
          } else {
            dispatch(push(`/create/${jobId}/getdata`))
          }
          break
        case 5:
          dispatch(push(`/job/${jobId}/details`))
          break
        case 6:
          if (job.isDataReady === false && job.dataPreparationRequest.status === 'Completed') {
            dispatch(push(`/create/${jobId}/inputdata`))
          } else if (currentPackage.inputTables.length === 0) {
            dispatch(push(`/create/${jobId}/review`))
          } else {
            dispatch(push(`/create/${jobId}/dataquality`))
          }
          break
        default:
          break
      }
    }
  }
}

export const setFilters = filters => ({
  type: JOBS_SET_FILTERS,
  filters,
})

export const clearFilters = filters => ({
  type: JOBS_CLEAR_FILTERS,
  filters,
})

const adminJobResetStart = () => ({
  type: JOBS_ADMIN_RESET_START,
})

const adminJobResetEnd = jobId => ({
  type: JOBS_ADMIN_RESET_END,
  jobId,
})

const adminJobRetryStart = () => ({
  type: JOBS_ADMIN_RETRY_START,
})

const adminJobRetryEnd = () => ({
  type: JOBS_ADMIN_RETRY_END,
})

export function adminResetJob({ jobId }) {
  return (dispatch) => {
    dispatch(adminJobResetStart())

    putRequest(`/admin/job/${jobId}/reset`)
      .then(() => {
        dispatch(adminJobResetEnd(jobId))
        dispatch(notify('Job reset to input data step'))
      })
      .catch(() => {
        dispatch(notify('Resetting job failed', 'error'))
      })
  }
}

export function adminRetryJob({ jobId }) {
  return (dispatch) => {
    dispatch(adminJobRetryStart())

    putRequest(`/admin/job/${jobId}/retry`)
      .then(() => {
        dispatch(adminJobRetryEnd())
        dispatch(notify('Job re-submitted for execution'))
      })
      .catch(() => {
        dispatch(notify('Retrying job failed', 'error'))
      })
  }
}
