import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icons } from '@deloitte/gel-library'
import Card from 'components/Card'
import PasswordHolder from 'components/PasswordHolder'
import SubmitButton from 'components/SubmitButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useDispatch } from 'react-redux'
import { downloadStatuses } from 'utils/business/jobs'
import useDownloads from 'hooks/useDownloads'
import HeightAnimator from 'components/HeightAnimator/HeightAnimator'
import styles from './Download.scss'

const propTypes = {
  disabled: PropTypes.bool,
  download: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
}

const defaultProps = {
  disabled: false,
}

function Download({
  jobId,
  disabled,
  download,
}) {
  const {
    displayName, status, name, key,
  } = download

  const dispatch = useDispatch()
  const {
    getDownloadUpdate,
    requestJobDownload,
    handleJobDownload,
  } = useDownloads(jobId)

  const [initialStatus] = useState(status)
  const [showComplete, setShowComplete] = useState(false)

  useEffect(() => {
    let interval = null

    if (status === downloadStatuses.downloading) {
      interval = setInterval(() => {
        getDownloadUpdate(name)
      }, 10000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [getDownloadUpdate, status, name])

  useEffect(() => {
    if (initialStatus === downloadStatuses.notStarted && status === downloadStatuses.complete) {
      setShowComplete(true)
      setTimeout(() => {
        setShowComplete(false)
      }, 2000)
    }
  }, [dispatch, jobId, name, status, initialStatus])

  const isComplete = status === downloadStatuses.complete
  const isSubmitting = status === downloadStatuses.requesting || status === downloadStatuses.downloading
  const handleClick = isComplete
    ? () => handleJobDownload(name)
    : () => requestJobDownload(download.name)

  return (
    <div className={styles.container}>
      <HeightAnimator>
        <Card className={classnames(styles.base,
          {
            [styles.disabled]: disabled,
            [styles.complete]: isComplete,
          })}
        >
          <div className={styles.strip}>
            <div className={classnames(styles.downloadResources, 'no-gutters')}>
              <Icons.IconFile className={styles.fileIcon} extension="ZIP" />
              <div className={styles.details}>
                <p className={styles.fileName} title={displayName}>
                  {`Retrieve ${displayName.toLowerCase()}`}
                </p>
                <p className={styles.description}>
                  <Icons.IconLock
                    className={styles.lockIcon}
                    height={13}
                    width={13}
                  />
                  Password protected
                </p>
              </div>
              <div className={styles.passwordContainer}>
                {isComplete && (
                  <PasswordHolder password={key} />
                )}
              </div>
              <div>
                <SubmitButton
                  className={styles.downloadButton}
                  complete={isComplete && showComplete}
                  disabled={disabled}
                  icon={<GetAppIcon />}
                  onClick={handleClick}
                  submitting={isSubmitting}
                  submittingText="Processing"
                >
                  {isComplete && !showComplete ? 'DOWNLOAD' : 'REQUEST'}
                </SubmitButton>
              </div>
            </div>
          </div>
        </Card>
      </HeightAnimator>
    </div>
  )
}

Download.propTypes = propTypes
Download.defaultProps = defaultProps

export default Download
