import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { formatDateTimeFromStringLong } from 'utils/dates'
import { sortByFunction } from 'utils/arrays'
import { Button } from '@deloitte/gel-library'
import TextArea from 'components/TextArea'
import attachFileIcon from 'images/attach-file-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import useApp from 'hooks/useApp'
import styles from './Comments.scss'
import { fetchSupportingData, updateSupportingFiles, updateSupportingFilesStatus } from '../../../actions/job'
import { downloadAttachedFile, removeSupportingFile, uploadSupportingFiles } from '../../../actions/file'
import fileIcon from '../../../images/file-icon-green.svg'
import binIcon from '../../../images/binIcon.svg'
import Spinner from '../../../components/Spinner'
import useTask from '../../hooks/useTask'
import useData from '../../hooks/useData'

const Comments = ({
  className,
  actionSetId,
  taskId,
  commentType,
  disabled,
  variant = { attachable: false, resolvable: false },
  dataId,
}) => {
  const [commentText, setCommentText] = useState('')
  const chatViewRef = useRef(null)
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)
  const { showNotification } = useApp()

  const { submitTaskComment, setTaskCommentStatus, setTaskPriority } = useTask(actionSetId, taskId)
  const { data, submitDataComment } = useData(actionSetId, taskId, dataId || null)

  const {
    job: {
      jobId, supportingFileStatus, supportingFiles, _isUploadingFiles,
    },
    app: { user: { isExternal } },
  } = useSelector(state => state)

  const taskComments = useSelector(state => (state.actionHub.tasks[taskId] || {}).comments)

  const comments = useMemo(() => {
    if (dataId) {
      return data?.comments || []
    }
    return taskComments || []
  }, [dataId, data?.comments, taskComments])

  const task = useSelector(state => state.actionHub.tasks[taskId])
  const isResolved = task?.commentStatusId === 4 || task?.commentStatusId === 5

  const commentAuthors = useSelector(state => state.actionHub.commentAuthors)

  useEffect(() => {
    if (chatViewRef.current) {
      chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight
    }
  }, [comments])

  useEffect(() => {
    if (commentType === 0) {
      dispatch(fetchSupportingData(jobId, isExternal, actionSetId, taskId, '0'))
    } else {
      dispatch(fetchSupportingData(jobId, isExternal, actionSetId, taskId, '1'))
      dispatch(fetchSupportingData(jobId, isExternal, actionSetId, taskId, '2'))
    }
  }, [dispatch, jobId, isExternal, actionSetId, taskId, commentType])

  const newSupportingFileStatus = useMemo(() => supportingFileStatus.filter(
    file => !comments.some(comment => comment.fileNames?.includes(file.name)),
  ), [supportingFileStatus, comments])

  const sortedComments = useMemo(() => {
    const isCommentTypeFiltered = (comment) => {
      if (commentType === 0) { return comment.commentTypeId === 0 }
      if (commentType === 1 || commentType === 2) { return comment.commentTypeId === 1 || comment.commentTypeId === 2 }
      return true
    }

    return (comments || [])
      .filter(isCommentTypeFiltered)
      .sort(sortByFunction(x => x.postedAt, true))
  }, [comments, commentType])

  const handleFileChange = useCallback((event) => {
    const filesArr = Array.from(event.target.files)
    const duplicateFiles = filesArr.filter(file => supportingFiles.some(f => f.name === file.name))

    if (duplicateFiles.length > 0) {
      showNotification('Files with duplicate names cannot be uploaded.', null, 'error')
    } else {
      const newFilesWithStatus = filesArr.map(file => ({
        name: file.name,
        size: file.size,
        status: 'pending',
        tablename: `${actionSetId}/${taskId}/${commentType}`,
        date: new Date(),
      }))
      dispatch(updateSupportingFiles([...supportingFiles, ...filesArr]))
      dispatch(updateSupportingFilesStatus([...supportingFileStatus, ...newFilesWithStatus]))
    }
  }, [supportingFiles, dispatch, supportingFileStatus, actionSetId, taskId, commentType, showNotification])

  const handleFileDeletion = useCallback((fileToDelete) => {
    const isFileComplete = supportingFileStatus.find(file => file.name === fileToDelete.name)?.status === 'complete'
    const removeFilePromise = isFileComplete
      ? dispatch(removeSupportingFile({ name: fileToDelete.name, tablename: fileToDelete.tablename }, jobId, isExternal))
      : Promise.resolve()

    removeFilePromise
      .then(() => {
        const newSupportFiles = supportingFiles.filter(file => file.name !== fileToDelete.name)
        const newFileStatus = supportingFileStatus.filter(file => file.name !== fileToDelete.name)
        dispatch(updateSupportingFiles(newSupportFiles))
        dispatch(updateSupportingFilesStatus(newFileStatus))
      })
      .catch(() => {
        showNotification('Failed to remove file.', null, 'error')
      })
  }, [dispatch, supportingFiles, supportingFileStatus, jobId, isExternal, showNotification])

  const handleFileDownload = useCallback((file) => {
    const fileDto = {
      name: file.name,
      tablename: file.tablename,
    }
    dispatch(downloadAttachedFile(fileDto, jobId, isExternal))
  }, [dispatch, jobId, isExternal])

  const handlePostClick = useCallback(() => {
    if (variant.attachable) {
      const PendingFilesStatus = supportingFileStatus.filter(f => f.status !== 'complete')
      const formsData = PendingFilesStatus
        .map((pendingFileStatus) => {
          const pendingFile = supportingFiles.find(f => f.name === pendingFileStatus.name)
          if (!pendingFile) { return null }
          const formData = new FormData()
          formData.append('file', pendingFile, pendingFile.name)
          formData.append('tableCode', pendingFileStatus.tablename)
          formData.append('jobId', jobId)
          formData.append('qqfilename', pendingFile.name)
          formData.append('locationRef', 'supporting_files')
          return formData
        })
        .filter(Boolean)
      if (formsData.length > 0) {
        dispatch(uploadSupportingFiles(formsData, isExternal))
          .then(() => {
            const updatedFileStatus = supportingFileStatus.map(file => (PendingFilesStatus.some(f => f.name === file.name) ? { ...file, status: 'complete' } : file))
            dispatch(updateSupportingFilesStatus(updatedFileStatus))
          })
          .catch((error) => {
            showNotification('Failed to upload files. Please try again.', error, 'error')
          })
      }

      const pendingFilesNames = PendingFilesStatus.map(f => f.name)
      submitTaskComment(commentText, pendingFilesNames, commentType)
    } else {
      if (!dataId || !submitDataComment) {
        showNotification('Cannot post comment - dataId is required for data comments', null, 'error')
        return
      }
      submitDataComment(commentText)
    }
    setCommentText('')
  }, [
    variant.attachable,
    supportingFileStatus,
    submitTaskComment,
    submitDataComment,
    commentText,
    commentType,
    supportingFiles,
    jobId,
    dispatch,
    isExternal,
    showNotification,
    dataId,
  ])

  const handleStatusChange = (statusId) => {
    setTaskCommentStatus(statusId)
  }

  const renderComment = useCallback(({
    id, message, userId, postedAt, fileNames, commentTypeId,
  }) => {
    const commentAuthor = commentAuthors[userId]
    const filteredFiles = supportingFileStatus?.filter(file => fileNames?.includes(file.name))

    return (
      <div
        className={styles.previousComment}
        data-comment-type={commentTypeId}
        key={id}
      >
        <div className={styles.commentContent}>
          <div className={styles.commentHeader}>
            <div>{formatDateTimeFromStringLong(postedAt)}</div>
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div>
              {commentAuthor
                ? `${commentAuthor.details.firstName} ${commentAuthor.details.surname} (${commentAuthor.details.email})`
                : 'Loading...'}
            </div>
          </div>
          <div className={styles.comment}>{message}</div>
          <div className={styles.attachments}>
            {filteredFiles?.map(file => (
              <div className={styles.attachment} key={file.name}>
                <img alt="File Icon" className={styles.fileIcon} src={fileIcon} />
                <span
                  className={styles.fileName}
                  onClick={() => handleFileDownload(file)}
                >
                  {file.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }, [handleFileDownload, supportingFileStatus, commentAuthors])

  const renderCommentButtons = () => {
    if (!variant.resolvable) { return null }

    return (
      <div className={styles.commentButtons}>
        {!isResolved ? (
          <>
            <Button
              mode="flat-secondary"
              onClick={() => handleStatusChange(5)}
              style={{ padding: '6px 12px' }}
            >
              NO LONGER REQUIRED
            </Button>
            <Button
              mode="primary"
              onClick={() => handleStatusChange(4)}
              style={{ padding: '6px 12px' }}
            >
              MARK AS RESOLVED
            </Button>
          </>
        ) : (
          <Button
            mode="flat-secondary"
            onClick={() => handleStatusChange(1)}
            style={{ padding: '6px 12px' }}
          >
            REQUIRES NEW COMMENTS
          </Button>
        )}
      </div>
    )
  }

  const renderFileList = () => {
    if (!variant.attachable) { return null }

    return (
      <div className={styles.fileList}>
        {newSupportingFileStatus?.map(file => (
          <div className={styles.fileItem} key={file.name}>
            <img alt="File Icon" className={styles.fileIcon} src={fileIcon} />
            <span
              className={styles.fileName}
              onClick={() => handleFileDownload(file)}
            >
              {file.name}
            </span>
            <button
              aria-label={`Delete ${file.name}`}
              className={styles.deleteButton}
              onClick={() => handleFileDeletion(file)}
              type="button"
            >
              <img alt="Delete" className={styles.deleteIcon} src={binIcon} />
            </button>
          </div>
        ))}
      </div>
    )
  }

  const renderControls = () => {
    return (
      <div className={styles.controls}>
        {variant.attachable && (
          <>
            <button
              aria-label="Attach file"
              className={styles.attachButton}
              onClick={() => fileInputRef.current?.click()}
              type="button"
            >
              <img alt="Attach file Icon" className={styles.attachIcon} src={attachFileIcon} />
            </button>
            <input
              id="attach-file"
              onChange={handleFileChange}
              onClick={(e) => {
                e.target.value = ''
              }}
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
            />
          </>
        )}
        <Button
          disabled={
            (commentText.trim().length === 0 || disabled)
            && (supportingFiles.length === 0 || !supportingFileStatus.some(f => f.status !== 'complete'))
          }
          mode="secondary"
          onClick={handlePostClick}
        >
          POST
          {_isUploadingFiles && <Spinner className={styles.spinner} />}
        </Button>
      </div>
    )
  }

  const commentCount = useMemo(() => comments.filter(comment => comment.commentTypeId === commentType).length,
    [comments, commentType])

  const previousCommentCountRef = useRef(commentCount)

  useEffect(() => {
    if (commentCount >= 1 && previousCommentCountRef.current === 0) {
      setTaskPriority(2)
    }
    previousCommentCountRef.current = commentCount
  }, [commentCount, setTaskPriority])

  return (
    <div className={className}>
      {(!variant.resolvable || !isResolved) && !disabled && (
        <div>
          <TextArea
            className={styles.textArea}
            disabled={disabled}
            height="120px"
            onChange={setCommentText}
            placeholder="Type your comment here..."
            value={commentText}
          />
          <div className={styles.actionBar}>
            {renderFileList()}
            {renderControls()}
          </div>
        </div>
      )}
      {commentCount >= 1 && (
        <div className={styles.commentsHeader}>
          <div className={styles.commentsTitle}>Posted comments</div>
          {renderCommentButtons()}
        </div>
      )}
      <div className={styles.previousComments} ref={chatViewRef}>
        {sortedComments.map(renderComment)}
      </div>
    </div>
  )
}

Comments.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  className: PropTypes.string,
  commentType: PropTypes.oneOf([0, 1, 2]).isRequired,
  dataId: PropTypes.number,
  disabled: PropTypes.bool,
  taskId: PropTypes.string.isRequired,
  variant: PropTypes.shape({
    attachable: PropTypes.bool,
    resolvable: PropTypes.bool,
  }),
}

Comments.defaultProps = {
  className: null,
  dataId: null,
  disabled: false,
  variant: { attachable: false, resolvable: false },
}

export default React.memo(Comments)
