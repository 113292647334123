import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'
import styles from './LabelField.scss'
import Parameter from '../../../views/Create/components/Parameter'
import { validateWithDependencies } from '../../utils/dependencies'

const labelFieldPropTypes = {
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.shape({
    dependencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    field: PropTypes.object.isRequired,
    formData: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isBulkUpdateModal: PropTypes.bool,
  modifiedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  setModifiedFields: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  validateLabel: PropTypes.func.isRequired,
}

const labelFieldDefaultProps = {
  isBulkUpdateModal: false,
}

function LabelField({
  field, disabled, isBulkUpdateModal, modifiedFields, setModifiedFields, setValue, validateLabel,
}) {
  const [currentFieldValue, setCurrentFieldValue] = useState('')
  const isFieldModified = useMemo(() => {
    return modifiedFields.some(f => f === field.id)
  }, [field.id, modifiedFields])

  const updateData = useCallback((formValue) => {
    if (isBulkUpdateModal && !isFieldModified) {
      return { ...field.formData, value: '' }
    }
    return formValue
  }, [field.formData, isBulkUpdateModal, isFieldModified])

  const checkIfModified = useCallback((value) => {
    if (field.formData.value !== value) {
      if (!modifiedFields.includes(field.id)) {
        setModifiedFields(currentArray => [...currentArray, field.id])
      }
    } else {
      if (!modifiedFields.includes(field.id)) {
        setModifiedFields(currentArray => [...currentArray, field.id])
      }
      const updatedModifiedArray = modifiedFields.filter(f => f.id !== field.id)
      setModifiedFields(updatedModifiedArray)
    }
  }, [field.formData.value, field.id, modifiedFields, setModifiedFields])

  const fieldName = useMemo(() => {
    return field.name.replace(/\s+/g, '-')
  }, [field.name])

  return (
    <div className={field.isDisabled ? styles.conditionallyDisabled : null} data-test-id={fieldName}>
      <p className={styles.label}>
        {field.name}
        {field.isRequired && !field.isDisabled && <span className={styles.required}>Required</span>}
      </p>
      <Parameter
        liveValidation
        simple
        className={styles[field.field.type]}
        dataTestId={fieldName}
        disabled={disabled || field.isDisabled}
        field={field.field}
        formData={updateData(field.formData)}
        label={field.name}
        onChange={(_, value) => {
          setValue(field.id, value)
          setCurrentFieldValue(value)
          checkIfModified(value)
        }}
        onValidation={() => {
          if (isBulkUpdateModal && currentFieldValue === '' && field.field.type === 'text') {
            setValue(field.id, '')
          }
          validateWithDependencies(field.id, field.formData.value, field.dependencies && field.dependencies.filter(d => d.type === 0), validateLabel)
        }}
      />
    </div>
  )
}

LabelField.propTypes = labelFieldPropTypes
LabelField.defaultProps = labelFieldDefaultProps

export default LabelField
