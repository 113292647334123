import React from 'react'
import PropTypes from 'prop-types'
import User from 'components/User'
import { commaFormatNumber, formatMoneyNoRounding } from 'utils/numbers'
import { formatDateFromStringShort } from 'utils/dates'
import { userPropType } from 'utils/propTypes'
import Priority from '../Priority'
import Status from '../Status'
import styles from './DataItem.scss'
import ActionState from '../ActionState'
import CommentStatus from '../CommentStatus'

function DataItem({
  actionStates, column, value, members, priorities, statuses, tagsList, isTaskList, commentStatuses, clients,
}) {
  let valueString

  switch (column.dataType) {
    case 'DATE':
      valueString = formatDateFromStringShort(value)
      break
    case 'MONEY':
      valueString = formatMoneyNoRounding(+value, column.currency)
      break
    case 'INT':
    case 'BIGINT':
    case 'TINYINT':
      valueString = commaFormatNumber(+value)
      break
    case 'FLOAT':
      if (value === null) {
        valueString = ''
      } else {
        valueString = column.currency
          ? formatMoneyNoRounding(+value, column.currency)
          : commaFormatNumber(+value)
      }
      break
    default:
      valueString = value || ''
  }

  const userField = (users) => {
    return value
      ? <User user={users.find(m => m.id === value)} />
      : <span className={styles.unassigned}>Unassigned</span>
  }

  switch (column.name) {
    case 'assignedTo':
    case 'reviewerId':
      return userField(members)
    case 'clientTeamId':
      return userField(clients)
    case 'priorityId':
      return <Priority priority={priorities.find(p => p.id === value)} showDescription={false} />
    case 'statusId':
      return <Status status={statuses.find(s => s.id === value)} />
    case 'taskActionId':
      return <ActionState actionState={actionStates.find(s => s.id === value)} />
    case 'tagId':
      return value && <span className={styles.tag}>{tagsList.find(t => t.id === value)?.name}</span>
    case 'Short description':
    {
      const shortDescString = valueString.length >= 100 && isTaskList ? (`${valueString.substring(0, 100)}...`) : valueString
      return (
        <span title={valueString}>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles.shortDesc} dangerouslySetInnerHTML={{ __html: shortDescString.split('\\n').join('<br>') }} />
        </span>
      )
    }
    case 'commentStatusId':
      return <CommentStatus commentStatus={commentStatuses.find(s => s.id === value)} />
    default:
    {
      valueString = valueString === null ? '' : valueString
      return (
        <span title={valueString}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: valueString.split('\\n').join('<br>') }} />
        </span>
      )
    }
  }
}

DataItem.propTypes = {
  actionStates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  clients: PropTypes.arrayOf(userPropType),
  column: PropTypes.shape({
    currency: PropTypes.string,
    dataType: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  commentStatuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  isTaskList: PropTypes.bool,
  members: PropTypes.arrayOf(userPropType),
  priorities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  statuses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  tagsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

DataItem.defaultProps = {
  isTaskList: false,
  actionStates: [],
  clients: [],
  members: [],
  priorities: [],
  statuses: [],
  value: '',
  tagsList: [],
  commentStatuses: [],
}

export default DataItem
