import React, { Fragment, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Heading } from '@deloitte/gel-library'
import useDownloads from 'hooks/useDownloads'
import Download from 'views/Job/components/Download'
import ClientReportList from 'components/ClientReportList'
import Container from 'components/layout/Grid/Container'
import ReActivateInfoCard from 'components/ReActivateInfoCard'
import { isTacTClient } from 'utils/permissions'
import useJob from 'hooks/useJob'
import styles from './ClientReports.scss'
import ClientJobDetailsSideBar from './ClientJobDetailsSideBar'

const propTypes = {
  job: PropTypes.shape({
    actions: PropTypes.shape({
      _hasFetchedAllActions: PropTypes.bool,
      currentActions: PropTypes.arrayOf(PropTypes.object),
      potentialActions: PropTypes.arrayOf(PropTypes.object),
      previousActions: PropTypes.arrayOf(PropTypes.object),
    }),
    analyses: PropTypes.arrayOf(PropTypes.object),
    analysisPeriod: PropTypes.string,
    hasExpired: PropTypes.bool,
    jobId: PropTypes.string,
    lastExecutionDate: PropTypes.object,
    name: PropTypes.string,
    packageId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    packageName: PropTypes.string,
    tables: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  uploaders: PropTypes.shape({
    uploaders: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    isExternal: PropTypes.bool,
  }).isRequired,
  validations: PropTypes.shape({
    validations: PropTypes.object,
  }).isRequired,
}

function ClientReports({
  job, user,
}) {
  const { downloads } = useDownloads(job ? job.jobId : null)

  const reportRef = createRef()

  const isTactClient = isTacTClient(user.isExternal, job.packageId)
  const { isAssignedActionsEditor } = useJob(job.jobId, true)

  return (
    <div>
      {!isAssignedActionsEditor ? (
        <Container>
          <div className="row">
            <div className={classnames('col-xs-12 col-sm-12 col-lg-12', styles.Download)}>
              {!isTactClient
              && (downloads && downloads.length > 0 && (
                <Fragment>
                  {downloads.map(d => <Download download={d} jobId={job.jobId} key={d.name} />)}
                </Fragment>
              ))}
            </div>
            <div className="col-xs-12 col-sm-8 col-lg-9">
              {(job?.hasExpired && user.isExternal) && (
                <ReActivateInfoCard />
              )}
              <div className={styles.reportSection} ref={reportRef}>
                <Heading className={styles.firstHeading} level={8}>Reports</Heading>
                <ClientReportList job={job} />
              </div>
            </div>

            <div className="col-xs-12 col-sm-4 col-lg-3">
              <ClientJobDetailsSideBar job={job} />
            </div>
          </div>
        </Container>
      ) : (
        <></>
      )}
    </div>
  )
}

ClientReports.propTypes = propTypes

export default ClientReports
