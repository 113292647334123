import React from 'react'
import PropTypes from 'prop-types'
import useApp from 'hooks/useApp'
import { HashLink as Link } from 'react-router-hash-link'
import { CONTACT_OPTIONS } from 'views/ContactForm/ContactForm'
import IconInfo from 'icons/IconInfo'
import styles from './JobInfoBanner.scss'

const JobInfoBanner = ({ isCloning, hasExpired, reachedExecutionLimit }) => {
  const { user } = useApp()

  const InfoText = () => {
    let text = 'You can no longer edit this job.'

    if (isCloning) {
      text = `Some values are automatically filled for you.
        Previously uploaded data, data errors and reports will not be copied.`
    } else if (hasExpired) {
      text = 'This job has expired, you are unable to make further changes.'
    } else if (reachedExecutionLimit) {
      text = !user.isExternal
        ? 'This job has exhausted the number of runs.'
        : (
          <>
            {'You can no longer edit this job. To reopen, please '}
            <Link to={`/support?option=${CONTACT_OPTIONS.ExtensionOfAccess}#contact`}>
              contact our team.
            </Link>
          </>
        )
    }
    return text
  }

  return (
    <div className={styles.base}>
      <IconInfo className={styles.icon} fill="#FFFFFF8F" height={20} width={20} />
      <InfoText />
    </div>
  )
}

JobInfoBanner.propTypes = {
  hasExpired: PropTypes.bool,
  isCloning: PropTypes.bool,
  reachedExecutionLimit: PropTypes.bool,
}

JobInfoBanner.defaultProps = {
  hasExpired: false,
  isCloning: false,
  reachedExecutionLimit: false,
}

export default JobInfoBanner
