/* eslint-disable */
import { TextInput } from '@deloitte/gel-library';
import axios from 'axios';
import Card from 'components/Card';
import Messagebar from 'components/Messagebar';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import {
  IconDocument, IconFileUpload
} from 'icons';
import { notify } from 'actions/app'
import IconLinkOpenInNew from 'icons/IconLinkOpenInNew';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-fine-uploader/dropzone';
import Spinner from 'components/Spinner'
import FileInput from 'react-fine-uploader/file-input';
import { keepAlive } from 'utils/api/app';
import { connect } from 'react-redux'
import FileGroupDialog from 'components/FileGroupDialog'
import { submittingFile, fetchingFilesWebsById, submittingWebsite, updatePercent, submittingFileWithoutDuplicate, completeFileUpload, completeWebUpload, uploadWebsite } from 'features/genAi/redux/actions/actionCreators'
const styles = require('./GenUploader.scss');

class GenUploader extends React.Component {

  state = {
    website: '',
    files: [],
    websites: [],
  }

  aiFileUploader = new FineUploaderTraditional({
    options: {
      chunking: {
        enabled: false
      },
      deleteFile: {
        enabled: true,
        endpoint: '/uploads'
      },
      request: {
        endpoint: this.props.endpoint,
        params: {
          genapp_id: this.props.appId,
          user_email: this.props.email
        }
      },
      cors: {
        expected: false,
        sendCredentials: true,
      },
      retry: {
        enableAuto: false
      },
      validation: {
        sizeLimit: 10 * 1024 * 1024
      },
    }
  })

  onValidationError = (id, name, errorReason) => {
    this.props.dispatch(notify(`Validation failed for ${name}: ${errorReason}`, 'error'));
  }

  componentDidMount() {
    keepAlive()
    this.props.fetchingFilesWebsById(this.props.appId)

    const onSubmited = (id, filename) => {
      this.props.submittingFileWithoutDuplicate({ filename: filename })
    }
    this.aiFileUploader.on('submitted', onSubmited)

    const completeCallback = (id, name, responseJSON, maybeXhr) => {
      this.props.completeFileUpload(responseJSON)
    }
    this.aiFileUploader.on('complete', completeCallback)
    this.aiFileUploader.on('error', this.onValidationError);
  }

  componentWillUnmount() {
    this.aiFileUploader.off('statusChange', this.statusChangeCallback)
    this.aiFileUploader.off('progress', this.progressCallback)
  }

  setInput = (field) => {
    this.setState({
      website: field,
    })
  }
  render() {
    return (
      <div className={styles.content}>
        <p>Personalise  the app with file upload and website data</p>
        <Messagebar type="info">
          Do not upload client or Deloitte private data
          {' '}
          {' '}
          <a className={styles.messageBarLink} onClick={() => window.open("https://ausdeloitte.sharepoint.com/sites/DOL-c-AU-FirmInnovation/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDOL%2Dc%2DAU%2DFirmInnovation%2FShared%20Documents%2FGenerative%20AI%2FUpdated%20Guidelines%20for%20use%20of%20Generative%20AI%5FJuly%202023%2Epdf&parent=%2Fsites%2FDOL%2Dc%2DAU%2DFirmInnovation%2FShared%20Documents%2FGenerative%20AI&OR=Teams%2DHL&CT=1692840748884&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzA2MDQwMTE3NyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D")}>
            Learn more
            <IconLinkOpenInNew height={10} width={10} />
          </a>
        </Messagebar>

        <h3 style={{}}> Document files </h3>

        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-start' }}>
          <div className={styles.dropZone} style={{ flex: '1 1 0px' }}>
            <Dropzone multiple className="dropClassName" dropActiveClassName="dropActiveClassName" uploader={this.aiFileUploader}>
              <div className="drag-text">Drop file to upload</div>
              <FileInput multiple accept=".csv, .md, .pdf, .txt" uploader={this.aiFileUploader}>
                <div className={styles.icon}><IconFileUpload width="54" /></div>
                <div className={styles.fileInfo}>
                  <p className={styles.fileInfoTitle}>Browse files or drop here</p>
                  <p className={styles.fileInfoSubtitle}>Supports TXT, PDF, Markup and CSV (less than 10 Mb per file)</p>
                </div>
              </FileInput>
            </Dropzone>
          </div>
        </div>

        {!this.props.fetching ? (
          <>
            <p style={{marginBottom:'0px', color:'#888888'}}> {Object.keys(this.props.files).length} uploaded files</p>
            <div className={styles.fileList}>
              {map(this.props.files, (fileDetails, fileId) => (
                <Card noPadding key={fileId} style={{ marginBottom: "5px" }}>
                  <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <div style={{ display: 'flex' }}>
                      <IconDocument height={20} width={20} />
                      <span className={styles.labelGrey} style={{ verticalAlign: 'sub', marginLeft: '5px' }}>{fileId}</span>
                      {fileDetails.loading && (
                        <div style={{ marginLeft: '10px' }}>
                          {fileDetails.percentage ? 'AI Vectorisation...' : <Spinner size="xxSmall" color="grey"/>}
                        </div>
                      )}
                      {/* <p> {fileDetails.file_size} </p> */}
                      {fileDetails.error && (
                        <div style={{ marginLeft: '10px', color: 'red' }}>
                          {fileDetails.error}
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </>
        ) : <>Fetching files...</>
        }

        {this.props.showDuplicateDialog && (
          <FileGroupDialog
            actionLabel="DISMISS"
            heading="Duplicate file found"
            // onAction={() => onCloseDuplicateDialog()}
            text={`A file with the same name was detected. The file with duplicate name is not uploaded, please change the name and reupload.`}
          />
        )}
      </div>
    )
  };
}

GenUploader.propTypes = {
  appId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

GenUploader.defaultProps = {
  appId: "",
  email: "",
  endpoint: "",
};

const mapStateToProps = state => ({
  files: state.genAi.aiApp.knowledge.files,
  fetching: state.genAi.aiApp.knowledge._isFileFetching,
  websites: state.genAi.aiApp.knowledge.websites,
  webFetching: state.genAi.aiApp.knowledge._isWebFetching,
  showDuplicateDialog: state.genAi.aiApp.knowledge._showDuplicateDialog,
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  submittingFile: (file) => dispatch(submittingFile(file)),
  completeFileUpload: (file) => dispatch(completeFileUpload(file)),
  uploadWebsite: (websitePayload) => dispatch(uploadWebsite(websitePayload)),
  fetchingFilesWebsById: (genAppId) => dispatch(fetchingFilesWebsById(genAppId)),
  updatePercent: (fileId, id) => dispatch(updatePercent(file)),
  submittingFileWithoutDuplicate: (filename) => dispatch(submittingFileWithoutDuplicate(filename)),
});


export default connect(mapStateToProps, mapDispatchToProps)(GenUploader);