import React from 'react'
import PropTypes from 'prop-types'
import MultiProgressBar from 'components/MultiProgressBar'
import { Icons } from '@deloitte/gel-library'
import ReactMarkdown from 'react-markdown'
import { formatPercent } from 'utils/numbers'
import styles from './ActionItem.scss'

function ActionItem({ action }) {
  const reviewCount = action.progress.find(x => x.id === 3).count
  const notStartedCount = action.progress.find(x => x.id === 1).count
  const excludedCount = action.progress.find(x => x.id === 0).count
  const percentNotStarted = (action.totalActions !== 0) ? (notStartedCount / action.totalActions) : 1
  const decimalPlaces = (percentNotStarted % 1 === 0) ? 0 : 1

  return (
    <div className={styles.base}>
      <Icons.IconTaskOverdue className={styles.taskIcon} size={20} />
      <div className={styles.chartSection}>

        <div className={styles.top}>
          <ReactMarkdown className={styles.description} source={action.summaryStatement} />
          <div className={styles.progress}>{`${formatPercent(percentNotStarted, decimalPlaces)} not started`}</div>
        </div>

        <MultiProgressBar
          denominator={action.totalActions}
          secondaryValue={excludedCount}
          value={reviewCount}
        />
      </div>
    </div>
  )
}

ActionItem.propTypes = {
  action: PropTypes.object.isRequired,
}

export default ActionItem

